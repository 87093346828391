import React from 'react'

import SecondNavigationBar from 'components/SecondNavigationBar'
import HomeIntroduceBlock from 'components/Home/HomeIntroduceBlock'

function HomePage({ location }) {
  return (
    <div>
      <SecondNavigationBar pathname={location.pathname} />
      <HomeIntroduceBlock />
    </div>
  )
}

export default HomePage
