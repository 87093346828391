/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'

//components
import DownloadAdminEditComponent from 'components/Support/DownloadAdminEditComponent'

// constants
import { DEFAULT_URL, SUPPORT_URL, FILE_URLS, HEADERS } from 'apis/restApis'
import { swalAction } from 'constants/methods'

// material-ui
import Button from '@material-ui/core/Button'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DescriptionIcon from '@material-ui/icons/Description'
import Tooltip from '@material-ui/core/Tooltip'

// CSS
import './DownloadTableComponent.scss'

const { DOWNLOAD_DELETE } = FILE_URLS
const { FILE_DOWNLOAD } = SUPPORT_URL
const getDownloadUrl = fileDir => `${DEFAULT_URL}${FILE_DOWNLOAD}${fileDir.replace('/backend/images', '')}`

const DownloadTableComponent = ({ downloadType, tableType, tableData, getDownloadData }) => {
  const authToken = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  // 관리자의 로그인 여부를 확인함
  const isLogin = useSelector(state => state.isLogin)


  // 하나의 카탈로그 파일 삭제
  const handleDelete = (fileId) => {
    const DELETE_DOWNLOAD_URL = DOWNLOAD_DELETE[downloadType]
    const url = `${DEFAULT_URL}${DELETE_DOWNLOAD_URL}/${fileId}`
    axios({ url, method: 'post', headers })
      .then(res => {
        if (res.data.result) {
          Swal.fire('삭제되었습니다.')
          getDownloadData()
        }
      })
      .catch(() => Swal.fire('삭제를 실패하였습니다.'))
  }

  const handleDeleteWithSwal = (fileId) => {
    swalAction('정말로 삭제하시겠습니까?', '삭제', () => handleDelete(fileId))
  }

  // 카탈로그 파일 이름 길이 고정
  const getFileName = (fileName) => {
    if (fileName.length > 20) {
      const cutFileName = fileName.slice(0, 20) + '...'
      return cutFileName
    }
    return fileName
  }

  return (
    <div className='DownloadTableComponent'>
      <div className='DownloadTableTitle'>
        <h2 className='TableTitle'>
          {tableType} {downloadType}
        </h2>
      </div>
      <TableContainer className='DownloadTableContainer' component={Paper}>
        <Table aria-label='simple table'>
          <TableHead className='TableHead'>
            <TableRow>
              <TableCell>{tableType} NAME</TableCell>
              <TableCell align='right'>FILE NAME</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map(({ id, targetName, files }) => (
              <TableRow key={id}>
                <TableCell component='th' scope='row'>
                  {targetName}
                  {isLogin && (
                    <div>
                      <DownloadAdminEditComponent
                        downloadType={downloadType}
                        id={id}
                        targetName={targetName}
                        tableType={tableType}
                        getDownloadData={getDownloadData}
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell align='right'>
                  {files.map(({ fileId, fileName, fileDir }) => (
                    <div key={fileId}>
                      <Tooltip title={fileName} arrow>
                        <Button
                          href={getDownloadUrl(fileDir)}
                          download
                        >
                          {getFileName(fileName)}
                          <DescriptionIcon className='DescIcon' />
                        </Button>
                      </Tooltip>
                      {isLogin && (
                        <Button onClick={() => handleDeleteWithSwal(fileId)}>
                          <DeleteForeverRoundedIcon color='secondary' />
                        </Button>
                      )}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  )
}

export default DownloadTableComponent
