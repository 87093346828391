import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import './SearchResultComponent.scss'

function SearchResultComponent({
  title,
  content,
  url,
  datetime,
}) {
  return (
    <div className='SearchResultComponent'>
      <Link to={url} className='SearchResultComponent__Link'>
        <div className='SearchResultComponent__Block'>
          <h3 className='SearchResultComponent__Block__Title'>{title}</h3>
          <div className='SearchResultComponent__Block__Content' dangerouslySetInnerHTML={{ __html: content }} />
          <div className='SearchResultComponent__Block__Date'>{datetime}</div>
        </div>
      </Link>
    </div>
  )
}

SearchResultComponent.prototype = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  datetime: PropTypes.string,
}

export default SearchResultComponent
