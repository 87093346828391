import {
  SET_NOTICE,
  SET_USER,
  SET_LOGIN,
  SET_PRODUCT,
} from './actionTypes'

export const setNotice = notice => ({ type: SET_NOTICE, payload: { notice } })

export const setUser = user => ({ type: SET_USER, payload: user })

export const setLogin = () => ({ type: SET_LOGIN })

export const setProduct = product => ({ type: SET_PRODUCT, payload: product })
