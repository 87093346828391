import React from 'react'
import SecondNavigationBar from 'components/SecondNavigationBar'

function PageNotFoundPage({ location }) {
  // FIXME - need to update the URI('/error')
  return (
    <div>
      <SecondNavigationBar pathname={location.pathname} />
      <div>PAGE NOT FOUND</div>
    </div>
  )
}

export default PageNotFoundPage
