import React from 'react'

import { TEMP_DEFAULT_URL } from 'apis/restApis'
import ModalImage from 'react-modal-image'

import './ApplicationComponent.scss'

function ApplicationComponent({ selectApplicationTitle, selectApplicationImage }) {
  return (
    <div className='ApplicationComponents'>
      <div className='ApplicationMain'>
        <p className='ApplicationCagtoryName'>
          {selectApplicationTitle}
        </p>
        <ModalImage
          small={TEMP_DEFAULT_URL + selectApplicationImage}
          large={TEMP_DEFAULT_URL + selectApplicationImage}
          alt={`Application ${selectApplicationTitle}`}
        />
      </div>
    </div>
  )
}

export default ApplicationComponent
