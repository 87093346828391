import React from 'react'
import { Link, Switch, Route } from 'react-router-dom'

import SecondNavigationBar from 'components/SecondNavigationBar'
import OverviewComponent from '../components/Introduce/OverviewComponent'
import HistoryComponent from '../components/Introduce/HistoryComponent'
import BusinessPartnerComponent from '../components/Introduce/BusinessPartnerComponent'
import BusinessAreaComponent from '../components/Introduce/BusinessAreaComponent'
import ContactComponent from '../components/Introduce/ContactComponent'

import './IntroducePage.scss'

function IntroducePage({ location }) {
  return (
    <div>
      <SecondNavigationBar pathname={location.pathname} />
      <IntroducePageLNB pathname={location.pathname} />
      <Switch>
        <Route path='/introduce/history' component={HistoryComponent} />
        <Route path='/introduce/business-area' component={BusinessAreaComponent} />
        <Route path='/introduce/business-partner' component={BusinessPartnerComponent} />
        <Route path='/introduce/contact' component={ContactComponent} />
        <Route path='/introduce' component={OverviewComponent} />
      </Switch>
    </div>
  )
}

const IntroducePageLNB = ({ pathname }) => (
  <div className='IntroducePage__LinkBox'>
    <Link
      to='/introduce'
      className={pathname === '/introduce' ? 'IntroducePage__Link__Select' : 'IntroducePage__Link'}
    >
      인사말
    </Link>
    <Link
      to='/introduce/history'
      className={pathname === '/introduce/history' ? 'IntroducePage__Link__Select' : 'IntroducePage__Link'}
    >
      연혁
    </Link>
    <Link
      to='/introduce/business-partner'
      className={pathname === '/introduce/business-partner' ? 'IntroducePage__Link__Select' : 'IntroducePage__Link'}
    >
      파트너
    </Link>
    <Link
      to='/introduce/business-area'
      className={pathname === '/introduce/business-area' ? 'IntroducePage__Link__Select' : 'IntroducePage__Link'}
    >
      사업 영역
    </Link>
    <Link
      to='/introduce/contact'
      className={pathname === '/introduce/contact' ? 'IntroducePage__Link__Select' : 'IntroducePage__Link'}
    >
      오시는 길
    </Link>
  </div>
)

export default IntroducePage
