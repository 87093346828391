import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { setUser, setLogin } from 'redux/actions'
import { linkTitles } from 'translations/ko'

import './NavigationBar.scss'

const {
  introduce: { linkText: introduceLinkText },
  products: { linkText: productsLinkText },
  applications: { linkText: applicationsLinkText },
  support: { linkText: supportLinkText },
} = linkTitles

const NavigationBar = () => {
  const isLogin = useSelector(state => state.isLogin)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(setLogin())
    dispatch(setUser({ username: '', token: '' }))
  }

  return (
    <div className='NavigationBar'>
      <div className='NavigationBar__UpperBox'>
        <div className='NavigationBar__Logo'>
          <Link to='/'>
            <img src='/images/huton-logo.jpg' alt='company logo in navigationbar' />
          </Link>
        </div>
        <div className='NavigationBar__LinkBox'>
          <Link
            to='/introduce'
            className='NavigationBar__Link'
          >
            {introduceLinkText}
          </Link>
          <Link
            to='/products'
            className='NavigationBar__Link'
          >
            {productsLinkText}
          </Link>
          <Link
            to='/applications'
            className='NavigationBar__Link'
          >
            {applicationsLinkText}
          </Link>
          <Link
            to='/support'
            className='NavigationBar__Link'
          >
            {supportLinkText}
          </Link>
          <Link
            to='/search'
            className='NavigationBar__Link'
          >
            <SearchIcon className='NavigationBar__SearchIcon' />
          </Link>
        </div>
      </div>
      {isLogin && (
        <div className='NavigationBar__AdminButtons'>
          <Button href='/introduce/history'>
            연혁 관리
          </Button>
          <Button href='/admin'>
            제품 관리
          </Button>
          <Button href='/support/download'>
            자료 관리
          </Button>
          <Button href='/support'>
            공지 관리
          </Button>
          <Button variant='contained' onClick={handleLogout}>
            로그아웃
          </Button>
        </div>
      )}
    </div>
  )
}

export default NavigationBar
