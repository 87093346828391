import React from 'react'
import { Link } from 'react-router-dom'

import './SideNavBar.scss'

import Button from '@material-ui/core/Button'

function SideNavBar({
  setSelectApplicationPk,
  selectApplicationPk,
  getApplicationDetail,
  allInfo,
}) {
  function toTheTop() {
    const scrollLocation = document.documentElement.scrollTop
    if (scrollLocation > 341) {
      window.scrollTo(0, 341)
    }
  }

  return (
    <div className='SideNavMain'>
      <div className='ScrolledNav'>
        <div className='CategoriesTag'>
          <p>APPLICATION</p>
        </div>
        {allInfo.map(({ applicationPk, applicationName }) => (
          <Link className='SideNavLink' to='/applications' key={applicationPk}>
            <Button
              className='SideNavButton'
              type='submit'
              onClick={() => {
                setSelectApplicationPk(applicationPk)
                getApplicationDetail(applicationPk)
                toTheTop()
              }}
            >
              <div className='SideNavTextDiv'>
                <p className={applicationPk === selectApplicationPk ? 'SelectSideNavText' : 'SideNavText'}>
                  {applicationName}
                </p>
              </div>
            </Button>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SideNavBar
