import React from 'react'
import { Link } from 'react-router-dom'

import './SideNavBar.scss'

import Button from '@material-ui/core/Button'

function SideNavBar({ setSelectedUpperCategory, selectedUpperCategory, allInfo }) {
  function toTheTop() {
    const scrollLocation = document.documentElement.scrollTop
    if (scrollLocation > 341) {
      window.scrollTo(0, 341)
    }
  }

  return (
    <div className='SideNavMain'>
      <div className='ScrolledNav'>
        <div className='CategoriesTag'>
          <p>PRODUCT</p>
        </div>
        {allInfo.map((categories, index) => (
          <Link 
            className='SideNavLink' 
            to='/products'
            key={categories.upperCategoryPk}
          >
            <Button
              className='SideNavButton'
              type='submit'
              onClick={() => {
                setSelectedUpperCategory(index)
                toTheTop()
              }}
            >
              <div className='SideNavTextDiv'>
                <p className={selectedUpperCategory === index ? 'SelectSideNavText' : 'SideNavText'}>
                  {categories.upperCategoryName}
                </p>
              </div>
            </Button>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SideNavBar
