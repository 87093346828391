/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import SecondNavigation from 'components/SecondNavigationBar'
import LoadingIndicator from 'components/LoadingIndicator'
import { Button } from '@material-ui/core'
import { SUPPORT_URL, DEFAULT_URL, HEADERS } from 'apis/restApis'

import './NoticeDetailComponent.scss'

const { NOTICE, NOTICE_DELETE, BASE } = SUPPORT_URL

const NoticeDetailComponent = ({ location, history }) => {
  const noticePk = useSelector(state => state.notice?.noticePk)
  const isLogin = useSelector(state => state.isLogin)
  const authToken = useSelector(state => state.user.token.token)
  const [selectedNotice, setSelectedNotice] = useState(null)
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  const getNotice = () => {
    const url = `${DEFAULT_URL}${NOTICE}/${noticePk}`
    axios({ url, method: 'get' })
      .then(res => {
        const { result, payload } = res.data
        // fast fail
        if (!result) {
          return null
        }

        setSelectedNotice(payload)
      })
      .catch(() => Swal.fire('에러가 발생했습니다. 다시 시도해 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
  }

  const deleteNotice = () => {
    const { noticePk: selectedNoticePk } = selectedNotice
    const url = `${DEFAULT_URL}${NOTICE_DELETE}/${selectedNoticePk}`
    axios({ url, headers, method: 'post' })
      .then(res => {
        const { data: { result } } = res

        if (result) {
          Swal.fire('삭제 완료')
          history.push('/support')
        }
      })
      .catch(() => Swal.fire('에러가 발생했습니다. 다시 시도해 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
  }

  useEffect(() => {
    getNotice()
  }, [])

  // fast fail
  if (selectedNotice == null) {
    return (
      <div className='NoticeDetail'>
        <SecondNavigation pathname={location.pathname} />
        <div>
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  // 데이터 렌더링을 위한 비구조화
  const {
    title, name, content, createDate, views
  } = selectedNotice
  const date = new Date(createDate)
  const YEAR = date.getFullYear()
  const MONTH = ('0' + (date.getMonth() + 1)).slice(-2)
  const DAY = ('0' + date.getDate()).slice(-2)
  const HOUR = date.getHours()
  const MINUTE = date.getMinutes()
  const createdAt = `${YEAR}.${MONTH}.${DAY}`

  // 렌더링
  return (
    <div className='NoticeDetail'>
      <SecondNavigation pathname={location.pathname} />
      <SupportPageLNB pathname={location.pathname} />
      <div className='NoticeDetail__Box'>
        <div className='NoticeDetail__TitleBox'>
          <h2 className='NoticeDetail__Title'>{title}</h2>
        </div>
        <div className='NoticeDetail__metaDataBox'>
          <div className='NoticeDetail__CreatedAt'>{createdAt}</div>
          <div className='NoticeDetail__Views'>조회 {views}</div>
        </div>
        <div className='NoticeDetail__ContentBox'>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className='NoticeDetail__ButtonBox'>
          {isLogin && (
            <div className='NoticeDetail__AdminButtonBox'>
              <Button
                className='NoticeDetail__UpdateButton'
                variant='contained'
                onClick={() => history.push(NOTICE)}
              >
                수정
              </Button>
              <Button
                className='NoticeDetail__DeleteButton'
                variant='contained'
                color='secondary'
                onClick={() => deleteNotice()}
              >
                삭제
              </Button>
            </div>
          )}
          <Button
            className='NoticeDetail__BackButton'
            variant='outlined'
            onClick={() => history.push(BASE)}
          >
            목록
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NoticeDetailComponent

const SupportPageLNB = ({ pathname }) => (
  <div className='LinkBox'>
    <Link
      to='/support'
      className={pathname.includes('notices') ? 'LinkBox__Link__Select' : 'LinkBox__Link'}
    >
      공지사항
    </Link>
    <Link
      to='/support/download'
      className={pathname === '/support/download' ? 'LinkBox__Link__Select' : 'LinkBox__Link'}
    >
      다운로드
    </Link>
  </div>
)
