/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import classNames from 'classnames'

import Button from '@material-ui/core/Button'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import TextField from '@material-ui/core/TextField'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import PublishIcon from '@material-ui/icons/Publish'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'

import { DEFAULT_URL, HEADERS, APPLICATIONS_URL } from 'apis/restApis'
import { APPLICATION_STATE } from 'constants/constants_pb'
import { swalAction } from 'constants/methods'

import './AdminApplication.scss'

const {
  GET_SYSTEMS,
  INSERT_SYSTEMS,
  DELETE_SYSTEMS,
  UPDATE_SYSTEMS,
} = APPLICATIONS_URL

const { CREATE, UPDATE } = APPLICATION_STATE

const DEFAULT_APPLICATION = {
  applicationName: '',
  originalName: '',
}

const AdminApplication = () => {
  const token = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': token,
  }
  const headersWithContentType = {
    ...headers,
    'Content-Type': 'multipart/form-data',
  }

  const [applications, setApplications] = useState([])
  const [applicationState, setApplicationState] = useState(UPDATE)
  const [application, setApplication] = useState(DEFAULT_APPLICATION)
  const [file, setFile] = useState(null)

  const [renderInputForm, setRenderInputForm] = useState(false)

  const getApplications = () => {
    const url = `${DEFAULT_URL}${GET_SYSTEMS}`
    const config = { url, HEADERS }

    axios(config)
      .then(res => {
        const { data: { payload } } = res
        const refinedPayload = payload.map(
          ({ applicationName, applicationPk, applicationFile }) => (
            {
              applicationName, applicationPk, originalName: applicationFile[0].originalName,
            }),
        )
        setRenderInputForm(false)
        setApplications(refinedPayload)
        setApplication(refinedPayload[0])
      })
      .catch(() => Swal.fire('제품을 가져오는데 실패하였습니다'))
  }

  const handleInsert = () => {
    const url = `${DEFAULT_URL}${INSERT_SYSTEMS}`
    const data = new FormData()
    data.append('file', file)
    data.append('applicationName', application.applicationName)

    axios({ url, headers: headersWithContentType, method: 'post', data })
      .then(() => {
        getApplications()
        setApplication('')
        setFile(null)
        setApplicationState(UPDATE)
        Swal.fire('저장하였습니다.')
      })
      .catch(() => Swal.fire('해당 파일을 저장하지 못했습니다.', '같은 이름의 파일이 이미 존재할 수 있습니다.'))
  }

  const handleUpdate = applicationPk => {
    const url = `${DEFAULT_URL}${UPDATE_SYSTEMS}/${applicationPk}`
    const { applicationName, originalName } = application
    const data = new FormData()
    data.append('file', file)
    data.append('applicationName', applicationName)
    data.append('originalName', originalName)

    const config = { url, method: 'post', headers, data }
    axios(config)
      .then(() => {
        getApplications()
        setFile(null)
        Swal.fire('수정하였습니다.')
      })
      .catch(() => Swal.fire('해당 파일을 수정하지 못했습니다.'))
  }

  const handleDelete = applicationPk => {
    const url = `${DEFAULT_URL}${DELETE_SYSTEMS}/${applicationPk}`
    const config = { url, method: 'post', headers }

    axios(config)
      .then(() => {
        getApplications()
        setFile(null)
        Swal.fire('삭제하였습니다.')
      })
      .catch(() => Swal.fire('해당 파일을 삭제하지 못했습니다.'))
  }

  const handleInsertWithSwal = () => {
    swalAction('생성하시겠습니까?', '확인', () => handleInsert())
  }

  const handleUpdateWithSwal = applicationPk => {
    swalAction('수정하시겠습니까?', '확인', () => handleUpdate(applicationPk))
  }

  const handleDeleteWithSwal = applicationPk => {
    swalAction('정말로 삭제하시겠습니까?', '삭제', () => handleDelete(applicationPk))
  }

  useEffect(() => {
    getApplications()
  }, [])

  const renderImageForm = () => (
    <div className='AdminApplication__FileForm'>
      <form
        className='ImageForm__Form'
        onSubmit={e => { e.preventDefault() }}
        encType='multipart/form-data'
      >
        <Button variant='contained' ctype='submit'>
          <label
            className='ImageForm__Label'
            htmlFor='file-application-upload-input'
          >
            <PublishIcon />
            <span>{application.applicationName} 이미지 파일 업로드</span>
            <input
              type='file'
              id='file-application-upload-input'
              name='file'
              accept='image/*'
              style={{ display: 'none' }}
              onChange={e => { setFile(e.target.files[0]) }}
            />
          </label>
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          disabled={application.originalName === null && file === null}
          onClick={() => {
            setFile(null)
            setApplication(prev => ({ ...prev, originalName: null }))
          }}
          className='ImageForm__DeleteButton'
        >
          파일 삭제
        </Button>
        <div className='ImageForm__Filename'>{file?.name || application.originalName}&nbsp;</div>
      </form>
    </div>
  )

  return (
    <div className='ADS'>
      <div className='ADS__Header'>
        <h3>APPLICATION 관리</h3>
      </div>
      <span className='ADS__Helptext'>
        <CheckIcon />
        APPLICATION 클릭하여 수정할 수 있습니다.
      </span>
      <div className='ADS__ApplicationBox'>
        {applications.map(elem => {
          return (<div
            className={classNames('ADS__Application', {
              clicked: elem.applicationPk === application.applicationPk
            })}
            key={elem.applicationPk}
            onClick={() => {
              setApplication(elem)
              setApplicationState(UPDATE)
            }}
          >
            <span>{elem.applicationName}</span>
          </div>
          )
        })}
      </div>
      <div className='ADS__Change__Box'>
        <h4 className='ADS__TITLE'>
          생성
        </h4>
        <div className='ADS__ShowInput'>
          <Button onClick={() => {
            setRenderInputForm(true)
            setApplication(DEFAULT_APPLICATION)
            setApplicationState(CREATE)
          }}>
            <AddCircleIcon />새로운 Application 추가하기
          </Button>
        </div>
        {
          applicationState === UPDATE ?
            <div>
              <h4 className='ADS__TITLE'>
                수정 및 삭제
              </h4>
              <div className='Admin__Button'>
                <Button type='submit'
                  onClick={() => {
                    setRenderInputForm(true)
                    setApplicationState(UPDATE)
                  }}>
                  <EditRoundedIcon color='primary' />
                  {application.applicationName}&nbsp; APPLICATION 수정하기
                </Button>
              </div>
              <div className='Admin__Button'>
                <Button
                  type='submit'
                  onClick={() => {
                    handleDeleteWithSwal(application.applicationPk)
                  }}
                >
                  <DeleteForeverRoundedIcon color='secondary' />
                  {application.applicationName}&nbsp; APPLICATION 삭제하기
                </Button>
              </div>
            </div>
            : <></>
        }
      </div>
      {renderInputForm && (
        <div className='AdminCategory__InputTab'>
          <hr />
          <div className='AdminCategory__UpperArea'>
            <h4 className='AdminCategory__InputTitle'>
              APPLICATION 관리자 입력란
            </h4>
            <Button type='submit' onClick={() => setRenderInputForm(false)}>
              <ClearIcon />
            </Button>
          </div>
          <TextField
            id='outlined-textarea'
            placeholder='NAME'
            multiline
            variant='outlined'
            value={application.applicationName}
            onChange={e => {
              setApplication(prev => ({ ...prev, applicationName: e.target.value }))
            }}
          />
          {renderImageForm()}
          <div className='ADS_Final_Button'>
            <Button
              onClick={() => {
                if (applicationState === CREATE) {
                  handleInsertWithSwal()
                } else {
                  handleUpdateWithSwal(application.applicationPk)
                }
              }}
              color='primary'
              variant='contained'
              disabled={application.applicationName === '' || file === null}
            >
              저장
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminApplication
