// libraries
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'

// constants
import { DEFAULT_URL, HEADERS, FILE_URLS, DOWNLOAD_URL } from 'apis/restApis'

// material-ui
import Button from '@material-ui/core/Button'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import PublishIcon from '@material-ui/icons/Publish'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// CSS
import './DownloadAdminCreateComponent.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const { UPLOAD_FILE } = FILE_URLS
const { INSERT } = DOWNLOAD_URL

const DownloadAdminCreateComponent = ({ downloadType, getDownloadData }) => {
  const token = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'Content-Type': 'multipart/form-data',
    'X-AUTH-TOKEN': token,
  }
  // 생성할 데이터 저장할 변수
  const [code, setCode] = useState('PRODUCT')
  const [targetName, setTargetName] = useState('')
  const [file, setFile] = useState(null)

  const sendFileToServer = () => {
    // 백엔드 서버로 보냄. (파일만 보내면 된다)
    const url = `${DEFAULT_URL}${UPLOAD_FILE}`
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      url,
      data: formData,
      headers,
      method: 'POST',
    }

    axios(config)
      .then(res => console.log(res))
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  const sendFileToDatabase = () => {
    // 디비로 보냄. 
    const INSERT_DOWNLOAD = INSERT[downloadType]
    const url = `${DEFAULT_URL}${INSERT_DOWNLOAD}`
    const formData = new FormData()
    formData.append('file', file)
    formData.append('code', code)
    formData.append('targetName', targetName)
    const config = {
      url,
      data: formData,
      headers,
      method: 'POST',
    }
    axios(config)
      .then(res => {
        const { data: { payload } } = res
        Swal.fire(payload)
        getDownloadData()
        setTargetName('')
        setFile(null)
      })
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  const handleSubmit = () => {
    sendFileToServer()
    sendFileToDatabase()
  }

  return (
    <div className='DownloadAdminCreateComponent'>
      <h3>{downloadType} 관리</h3>
      <h4>생성 방법</h4>
      <p>아래의 입력창에 내용을 입력후 생성하기 버튼을 눌러 생성합니다.</p>

      <div className='DownloadAdminCreateComponent__Input'>
        <FormControl required>
          <InputLabel id='demo-mutiple-name-label'>{downloadType} Type</InputLabel>
          <Select
            labelId='demo-mutiple-name-label'
            id='demo-mutiple-name'
            value={code}
            onChange={e => setCode(e.target.value)}
            MenuProps={MenuProps}
          >
            <MenuItem value='PRODUCT'>
              PRODUCT
            </MenuItem>
            <MenuItem value='APPLICATION'>
              APPLICATION
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          required
          id='standard-textarea'
          fullWidth
          label='NAME'
          value={targetName}
          onChange={e => setTargetName(e.target.value)}
        />
        <div className='DownloadAdminCreateComponent__Input__FileBox'>
          <p>업로드 할 파일을 선택해주세요</p>
          <form
            encType='multipart/form-data'
          >
            <div>
              <Button variant='contained' ctype='submit'>
                <label className='DownloadAdminCreateComponent__Input__Label' htmlFor='file-upload'>
                  <PublishIcon />업로드
                  <input
                    type='file'
                    id='file-upload'
                    name='file'
                    accept='image/*'
                    style={{ display: 'none' }}
                    onChange={e => setFile(e.target.files[0])}
                  />
                </label>
              </Button>
              <p>{file?.name}</p>
            </div>
          </form>

        </div>
        <div className='DownloadAdminCreateComponent__CreateButton'>
          {
            file !== null && code !== null && targetName !== null
              ? <Button variant='contained' color='primary' type='submit' onClick={() => handleSubmit()}> 생성하기</Button>
              : <Button variant='contained' disabled>생성하기</Button>
          }
        </div>
      </div>
      <hr />
      <h4>기존 데이터에 대한 추가 / 수정 / 삭제 방법</h4>
      <p>
        <h5>추가</h5>
        <AddCircleIcon />을 통해 해당 위치에 파일 추가가 가능합니다.
      </p>
      <p>
        <h5>수정</h5>
        <EditRoundedIcon />을 통해 위치(PRODUCT, APPLICATION) 변경 또는 NAME 변경이 가능합니다.

      </p>
      <p>
        <h5>삭제</h5>
        <DeleteForeverRoundedIcon color='secondary' /> 을 통해 삭제됩니다.
      </p>
    </div>
  )
}

export default DownloadAdminCreateComponent
