/* eslint-disable */
// libraires
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'

// components
import LoadingIndicator from 'components/LoadingIndicator'
import HistoryAdminCreateComponent from './HistoryAdminCreateComponent'
import HistoryAdminEditComponent from './HistoryAdminEditComponent'

// constants
import { DEFAULT_URL, HISTORY_URL } from 'apis/restApis'

// material-ui
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

// CSS
import './HistoryComponent.scss'

const HISTORY_DEFAULT_URL = `${DEFAULT_URL}${HISTORY_URL.BASE}`

const renderSwalErrorAction = () => {
  Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요')
}

const HistoryComponent = () => {
  const [history, setHistory] = useState(null)
  const [yearList, setYearList] = useState([])
  const [endYearList, setEndYearList] = useState([])

  // 관리자의 로그인 여부를 확인함
  const isLogin = useSelector(state => state.isLogin)

  const getYearList = () => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const years = []
    const endYears = ['선택안함']
    for (let i = currentYear; i >= 2002; i--) {
      years.push(i)
      endYears.push(i)
    }
    setYearList(years)
    setEndYearList(endYears)
  }

  // 데이터 변경(추가, 수정, 삭제) 시 데이터 새로 가져오기
  const getHistoryData = () => {
    axios.get(HISTORY_DEFAULT_URL)
      .then(res => {
        const { result, payload } = res.data
        if (!result) {
          renderSwalErrorAction()
          return
        }
        setHistory(payload)
      })
      .catch(() => renderSwalErrorAction())
  }

  useEffect(() => {
    axios.get(HISTORY_DEFAULT_URL)
      .then(res => {
        const { result, payload } = res.data
        if (!result) {
          renderSwalErrorAction()
          return
        }
        setHistory(payload)
        getYearList()
      })
      .catch(() => renderSwalErrorAction())
  }, [])

  // 연도 보여주기 (endYear 0일 경우 startYear만 보이기)
  const getYear = (startYear, endYear) => {
    if (endYear) {
      return <h2>{startYear} ~ {endYear}</h2>
    }
    return <h2>{startYear}</h2>
  }

  // 월 보여주기 (0월 제외)
  const getMonth = (month) => {
    if (month) { return <h4>{month}월</h4> }
  }

  // 로딩중 화면
  if (history == null) {
    return (
      <LoadingIndicator />
    )
  }


  // 데이터가 있는 경우 랜더링
  return (
    <div className='HistoryComponent'>
      <div className='HistoryComponent__Admin'>
        {isLogin && (
          <HistoryAdminCreateComponent
            getHistoryData={getHistoryData}
            yearList={yearList}
            endYearList={endYearList}
          />
        )}
      </div>
      <Timeline align='alternate'>
        {history.map(({ startYear, endYear, history }) => (
          <TimelineItem key={startYear}>
            <TimelineOppositeContent>
              <Typography color='textSecondary'>
                {getYear(startYear, endYear)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className='HistoryComponentBlock'>
                <Typography color='textSecondary'>
                  {history.map(({ month, contents }) => (
                    <div>
                      {getMonth(month)}
                      {contents.map(({ historyPk, content }) => (
                        <div>
                          <p>{content}</p>
                          {isLogin && (
                            <HistoryAdminEditComponent
                              startYear={startYear}
                              endYear={endYear}
                              month={month}
                              historyPk={historyPk}
                              content={content}
                              yearList={yearList}
                              getHistoryData={getHistoryData}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div >
  )
}

export default HistoryComponent

