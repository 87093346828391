import React from 'react'
import routes from './routes'

import './Route.scss'

function Route() {
  return (
    <>
      {routes}
    </>
  )
}

export default Route
