import React from 'react'
import './OverviewComponent.scss'

function OverviewComponent() {
  return (
    <div className='OverviewComponent' container>
      <h2>인사말</h2>
      <div className='OverviewComponent__Text'>
        <div>
          저희 휴톤은 디지털 방송 장비를 전문적으로 제조, 개발하는 업체로서 2002년 1월 창사 이래 끊임없는 선제적 기술 개발과 외부
          환경에 적응적인 서비스 지원을 바탕으로 고객에게 진정한 가치를 제공하는 회사로서 최고 품질의 제품을 생산 판매해 왔습니다.
        </div>
        <div>
          이는 회사 임직원의 지칠 줄 모르는 열정과 노력, 더불어 협력 업체의 지원을 통해 가능했습니다.
        </div>
        <br />
        <div>
          저희 휴톤은 현재 주어진 디지털 방송의 다양성에 부합하는 최적의 장비 개발 뿐 아니라 다가오는 미래 방송의 주력 분야인 초고화질 
          디지털 방송 장비 시장을 선도하고 이를 근간으로 사업의 다각화를 통해 빠른 속도로 발전하는 기술의 미래 가치와 인간의 감성을 담은
        </div>
        <div>
          - 친환경적이고 Intelligence한 제품.
        </div>
        <div>
          - 사람에게 친근하고 사용이 편리한 제품.
        </div>
        <div>
          - 재난, 긴급 상황 등 복합 환경 변화에 대응하는 제품.
        </div>
        <div>
          글로벌 네트워킹이 가능한 제품의 개발 에 매진하여 성심성의껏 최선을 다하는 기업으로 고객의 기대에 부응하는 휴톤이 되겠습니다.
        </div>
      </div>
      <div className='OverviewComponent__SignContainer'>
        <div className='OverviewComponent__CompanyTitle'>
          <span>주식회사</span><span>휴&nbsp;&nbsp;&nbsp;톤</span>
        </div>
        <div className='OverviewComponent__CompanyTitle'>
          <span>대표이사</span><span>김영상</span>
        </div>
      </div>
    </div>
  )
}
// font 20px bold
export default OverviewComponent
