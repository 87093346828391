/* eslint-disable */
import React, { useState } from 'react'
import CustomEditor from 'components/CustomEditor'
import { DEFAULT_URL, PRODUCT_URL, HEADERS } from 'apis/restApis'
import { useSelector } from 'react-redux'

import axios from 'axios'
import Swal from 'sweetalert2'
import Button from '@material-ui/core/Button'

import './ProductEditorComponent.scss'

const {
  UPDATE
} = PRODUCT_URL

function ProductEditorComponent(props) {
  const { history } = props
  const product = useSelector(state => state.product)
  const isLogin = useSelector(state => state.isLogin)
  const token = useSelector(state => state.user.token)
  const { token: authToken } = token
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  const {
    productPk,
    productCategoryPk,
    productName,
    keyFeature,
    contentHtml,
  } = product

  const [isPreview, setIsPreview] = useState(false)
  const [crntProductName, setCrntProductName] = useState(productName)
  const [crntKeyFeature, setCrntKeyFeature] = useState(keyFeature)
  const [editorContent, setEditorContent] = useState(contentHtml)

  if (!isLogin) {
    history.push('/')
  }

  const turnOnPreview = () => {
    setIsPreview(!isPreview)
  }

  const submitStrHtml = () => {
    const data = {
      productCategoryPk: productCategoryPk,
      productName: crntProductName,
      keyFeature: crntKeyFeature,
      contentHtml: editorContent,
    }

    const url = `${DEFAULT_URL}${UPDATE}/${productPk}`
    axios({ url, method: 'post', headers, data })
      .then(
        Swal.fire('성공적으로 수정됐습니다.')
          .then(() => {
            window.location.href = `/products/${productPk}`
          }),
      )
      .catch(err => {
        Swal.fire('에러가 발생했습니다.')
      })
  }

  const cancelCheck = () => {
    Swal.fire({
      title: '작성을 그만둡니까?',
      text: '그만두기 버튼 클릭 시 현재 내용이 저장되지 않습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#55555',
      confirmButtonText: '그만두기',
      cancelButtonText: '취소',
    }).then(result => {
      if (result.isConfirmed) {
        window.location.href = `/products/${productPk}`
      }
    })
  }

  return (
    <div className='ProductEditorComponent'>

      <div className='ProductEditorCommpoent__Wrapper'>

        <div className='EditorWrapper Editor__ProductName'>
          <h2>제품 이름</h2>
          <input
            value={productName}
            onChange={e => setCrntProductName(e)}
          />
        </div>

        <div className='EditorWrapper'>
          <h2>제품 특징</h2>
          <CustomEditor
            initialContent={keyFeature}
            setEditorContent={setCrntKeyFeature}
            height='250px'
          />
        </div>

        <hr />

        <div className='EditorWrapper'>
          <h2>제품 설명</h2>
          <CustomEditor
            initialContent={contentHtml}
            setEditorContent={setEditorContent}
            isProduct={true}
          />
        </div>

        <div className='btnDiv'>
          <Button className='btnPreview' variant='contained' onClick={() => turnOnPreview()}>미리보기</Button>
          <div>
            <Button className='btnCancel' variant='contained' color='secondary' onClick={() => cancelCheck()}>수정취소</Button>
            <Button className='btnSubmit' variant='contained' color='primary' onClick={() => submitStrHtml()}>수정완료</Button>
          </div>
        </div>
        <div className='previewName'>
          <h3 className='previewNameText'>하단에 제품 설명란의 미리보기가 표시됩니다.</h3>
        </div>
        {isPreview
          && (
            // eslint-disable-next-line
            <div className='preview'>
              <div className='overview' dangerouslySetInnerHTML={{ __html: editorContent }} />
            </div>)}
      </div>

    </div>
  )
}

export default ProductEditorComponent
