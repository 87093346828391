import React, { useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'
import Swal from 'sweetalert2'

import axios from 'axios'

import SecondNavigationBar from 'components/SecondNavigationBar'
import LoadingIndicator from 'components/LoadingIndicator'

import ApplicationComponent from 'components/Application/ApplicationComponent'
import SideNavBar from 'components/Application/SideNavBar'
import { DEFAULT_URL, HEADERS, APPLICATION_URL } from '../apis/restApis'

import './ApplicationPage.scss'

const applicationUrl = `${DEFAULT_URL}${APPLICATION_URL}`

const option = {
  url: applicationUrl,
  header: { HEADERS },
}

function ApplicationPage({ location, match }) {
  const [initialLoading, setInitialLoading] = useState(false)
  // 전체 데이터 들어가는 리스트
  const [allInfo, setAllInfo] = useState([])

  const [selectApplicationPk, setSelectApplicationPk] = useState('')
  const [selectApplicationTitle, setSelectApplicationTitle] = useState('')
  const [selectApplicationImage, setSelectApplicationImage] = useState('')

  const getApplicationDetail = applicationPk => {
    axios.get(`${applicationUrl}/${applicationPk}`, null, option.header)
      .then(({ data }) => {
        if (data.result) {
          const payloadData = data.payload
          setSelectApplicationTitle(payloadData.applicationName)
          setSelectApplicationImage(payloadData.applicationFile[0].url)
          if (selectApplicationImage !== undefined) {
            setInitialLoading(true)
          }
        }
      })
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  useEffect(() => {
    axios.get(applicationUrl, null, option.header)
      .then(({ data }) => {
        if (data == null) {
          return null
        }
        const { result, payload } = data
        if (result !== true) {
          return null
        }
        // 대분류 포함 모든 데이터: allInfo
        const infoArray = payload
        setAllInfo(infoArray)
        if (infoArray.length > 0) {
          setSelectApplicationTitle(infoArray[0].applicationName)
          if (match.params.applicationPk !== undefined) {
            getApplicationDetail(match.params.applicationPk)
            setSelectApplicationPk(match.params.applicationPk)
          } else {
            setSelectApplicationPk(infoArray[0].applicationPk)
            getApplicationDetail(infoArray[0].applicationPk)
          }
        }
      }, () => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }, [])

  if (!initialLoading) {
    return (
      <>
        <SecondNavigationBar pathname={location.pathname} />
        <div className='LoadingIndicator'>
          <LoadingIndicator />
        </div>
      </>
    )
  }

  return (
    <div>
      <SecondNavigationBar pathname={location.pathname} />
      <div className='ApplicationPage'>
        {/* 내브바 div */}
        <div className='SideNavDiv'>
          <Switch>
            <SideNavBar
              setSelectApplicationPk={setSelectApplicationPk}
              selectApplicationPk={selectApplicationPk}
              getApplicationDetail={getApplicationDetail}
              allInfo={allInfo}
            />
          </Switch>
        </div>
        {/* 컨텐츠 div */}
        <div className='ApplicationContent'>
          <ApplicationComponent
            selectApplicationTitle={selectApplicationTitle}
            selectApplicationImage={selectApplicationImage}
          />
        </div>
      </div>
    </div>
  )
}

export default ApplicationPage
