import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import SecondNavigation from 'components/SecondNavigationBar'
import { DEFAULT_URL, HEADERS, LOGIN_URL } from 'apis/restApis'
import { setUser, setLogin } from 'redux/actions'

const handleError = status => {
  switch (status) {
    case 400:
    case 401:
    case 403:
      Swal.fire('로그인 에러', '아이디 또는 비밀번호가 일치하지 않습니다', 'error')
      break
    case 500: // Internal Server Error
    case 501: // Not Implelmented
    case 502: // Bad Gateway
    case 503: // Service Unavailable
    case 504: // Gateway Timeout
      Swal.fire('서버 에러', '서버에 에러가 발생했습니다', 'error')
      break
    default:
      Swal.fire(`서버 에러 (에러 코드: ${status}`, '알 수 없는 에러가 발생했습니다. 관리자에게 문의해주세요', 'error')
      break
  }
}

const LoginComponent = () => {
  const dispatch = useDispatch()
  // variables for login
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    const url = `${DEFAULT_URL}${LOGIN_URL}`
    const loginData = { username, password }
    axios.post(url, loginData, HEADERS)
      .then(res => {
        const { result, payload } = res.data
        if (!result) { // 통신이 제대로 되지 않은 경우
          Swal.fire('에러 발생!', '알 수 없는 에러가 발생했습니다. 관리자에게 문의해주세요', 'error')
        }
        dispatch(setUser({ username, token: payload }))
        dispatch(setLogin())
      })
      .catch(err => {
        const { response } = err
        if (response == null) {
          Swal.fire('요청을 보낼 수 없습니다.', '인터넷 연결을 확인하세요')
          return
        }

        const { status } = response
        handleError(status)
      })
  }

  return (
    <div className='AdminPage'>
      <SecondNavigation />
      <h2 className='AdminPage__LoginTitle'>
        관리자 전용 페이지 입니다. 로그인을 해주세요.
      </h2>
      <div className='AdminPage__LoginBox'>
        <input
          className='AdminPage__Username'
          onChange={e => setUsername(e.currentTarget.value)}
          type='username'
          placeholder='Username'
        />
        <input
          className='AdminPage__Pwd'
          onChange={e => setPassword(e.currentTarget.value)}
          type='password'
          placeholder='Password'
          onKeyPress={
            e => {
              if (e.code === 'Enter') {
                handleLogin()
              }
            }
          }
        />
        <button
          className='AdminPage__LoginButton'
          type='submit'
          onClick={() => handleLogin()}
        >
          <span>
            로그인
          </span>
        </button>
      </div>
    </div>
  )
}

export default LoginComponent
