/* global kakao */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingIndicator from 'components/LoadingIndicator'
import { DEFAULT_URL, MAP_URL, HEADERS } from 'apis/restApis'

import './ContactMapComponent.scss'

const renderMap = mapApi => {
  // 로딩중 화면
  if (mapApi == null) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    )
  }

  // 데이터가 있는 경우 렌더링
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = `//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${mapApi}`
  script.onload = () => {
    kakao.maps.load(() => {
      const container = document.getElementById('map')
      const options = {
        center: new kakao.maps.LatLng(37.484663289568864, 126.88064027837333),
        level: 3,
      }
      const map = new window.kakao.maps.Map(container, options)

      const markerPosition = new kakao.maps.LatLng(
        37.484663289568864, 126.88064027837333,
      )
      // 마커를 생성
      const marker = new kakao.maps.Marker({
        position: markerPosition,
        clickable: true,
      })

      // 마커를 지도 위에 표시
      marker.setMap(map)
      // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우 생성
      const iwContent = '<div style="padding:5px;">Huton</div>'
      const iwRemoveable = true // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시

      // 인포윈도우를 생성
      const infowindow = new kakao.maps.InfoWindow({
        content: iwContent,
        removable: iwRemoveable,
      })

      // 마커에 클릭이벤트를 등록
      kakao.maps.event.addListener(marker, 'click',
        (() => {
          infowindow.open(map, marker)
        }))
    })
  }
  document.head.appendChild(script)
  return (
    <div id='map'> </div>
  )
}

function ContactMapComponent() {
  const [mapApi, setMap] = useState(null)

  useEffect(() => {
    const URL = `${DEFAULT_URL}${MAP_URL}`
    axios.get(URL, null, HEADERS)
      .then(res => {
        const { result, payload } = res.data
        if (!result) {
          Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요')
          return
        }
        setMap(payload)
      })
      .catch(() => Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
  }, [])

  return (
    <div>
      {renderMap(mapApi)}
    </div>
  )
}

export default ContactMapComponent
