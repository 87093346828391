/* eslint-disable */
// libraires
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'

// material-ui
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

// constants
import { DEFAULT_URL, HISTORY_URL, HEADERS } from 'apis/restApis'

// CSS
import './HistoryAdminCreateComponent.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const { HISTORY_CREATE } = HISTORY_URL
const url = `${DEFAULT_URL}${HISTORY_CREATE}`

const HistoryAdminCreateComponent = ({ getHistoryData, yearList, endYearList, history }) => {
  const isLogin = useSelector(state => state.isLogin)
  const authToken = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  // Data
  const [startYear, setStartYear] = useState('')
  const [endYear, setEndYear] = useState('')
  const [month, setMonth] = useState('')
  const [content, setContent] = useState('')

  // send Data
  const sendHistoryToServer = () => {
    if (endYear !== '' && startYear > endYear) {
      return Swal.fire('연도를 확인해주세요.')
    }
    if (endYear === startYear) {
      return Swal.fire('연도가 동일합니다.')
    }
    if (endYear === '선택안함') {
      setEndYear('')
    }
    const data = [{
      'startYear': startYear,
      'endYear': endYear,
      'month': month,
      'content': content,
    }]
    axios({ url, method: 'post', headers, data })
      .then(res => {
        const { data: { result } } = res
        // 초기화
        setStartYear('')
        setEndYear('')
        setContent('')
        setMonth('')
        if (result) {
          getHistoryData()
          return Swal.fire('연혁에 추가되었습니다.')
        }
      })
      .catch(() => Swal.fire('생성을 실패하였습니다.'))
  }

  // 연도, 월 선택 리스트 보여주기
  const showMenuItem = menus => menus.map(menu => {
    return <MenuItem value={menu}>{menu}</MenuItem>
  })

  useEffect(() => {
    if (endYear === '선택안함') {
      setEndYear('')
    }
  }, [endYear])

  // 로그인하지 않았다면 뒤로돌려보냄
  if (!isLogin) {
    history.push('/')
  }
  return (
    <div className='HistoryAdminCreateComponent'>
      <h3>연혁 관리</h3>
      <h4>생성 방법</h4>
      <p>아래의 입력창에 내용을 입력후 생성하기 버튼을 눌러 생성합니다.</p>
      <div className='HistoryAdminCreateComponent__Input'>
        <FormControl required>
          <InputLabel id='demo-mutiple-name-label'>시작 연도</InputLabel>
          <Select
            labelId='demo-mutiple-name-label'
            id='demo-mutiple-name'
            value={startYear}
            onChange={e => setStartYear(e.target.value)}
            helperText='필수'
            MenuProps={MenuProps}
          >
            {showMenuItem(yearList)}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id='demo-mutiple-name-label'>종료 연도</InputLabel>
          <Select
            labelId='demo-mutiple-name-label'
            id='demo-mutiple-name'
            value={endYear}
            onChange={e => setEndYear(e.target.value)}
            MenuProps={MenuProps}
            placeholder='선택'
          >
            {showMenuItem(endYearList)}
          </Select>
          <FormHelperText>
            (선택) 입력시
            <div>
              시작 연도 ~ 종료 연도
            </div>
          </FormHelperText>
        </FormControl>
        <TextField
          id='standard-select-currency'
          select
          label='월'
          className='HistoryAdminCreateComponent__Input__Month'
          value={month}
          onChange={e => setMonth(e.target.value)}
          helperText='(선택)'

        >
          {showMenuItem([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
        </TextField>
        <TextField
          required
          id='standard-textarea'
          label='연혁 내용'
          placeholder='필수'
          multiline
          value={content}
          className='HistoryAdminCreateComponent__Input__Content'
          onChange={e => setContent(e.target.value)}
        />
        <div className='CreateButton'>
          {
            content !== '' && startYear !== ''
              ? <Button variant='contained' color='primary' onClick={() => sendHistoryToServer()}>생성하기</Button>
              : <Button variant='contained' disabled>생성하기</Button>
          }
        </div>
      </div>
      <hr />
      <h4>수정 및 삭제 방법</h4>
      <p>
        각 내용 별 아이콘을 통해
        수정 <EditRoundedIcon />,
        삭제 <DeleteForeverRoundedIcon color='secondary' />
        가 가능합니다.
      </p>
    </div>
  )
}

export default HistoryAdminCreateComponent
