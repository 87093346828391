import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'

// components
import LoadingIndicator from 'components/LoadingIndicator'
import DownloadTableComponent from 'components/Support/DownloadTableComponent'
import DownloadAdminCreateComponent from 'components/Support/DownloadAdminCreateComponent'

// constants
import { DEFAULT_URL, DOWNLOAD_URL } from 'apis/restApis'

// CSS
import './DownloadCataloguesComponent.scss'

const CATALOG_URL = `${DEFAULT_URL}${DOWNLOAD_URL.CATALOGS}`

const renderSwalErrorAction = () => {
  Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요')
}

function DownloadCataloguesComponent() {
  const [productCatalogData, setProductCatalogData] = useState([])
  const [applicationCatalogData, setApplicationCatalogData] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  // 관리자의 로그인 여부를 확인함
  const isLogin = useSelector(state => state.isLogin)

  // 다운로드 페이지 카탈로그 데이터 조회
  const getDownloadData = () => {
    axios.get(CATALOG_URL)
      .then(res => {
        const { result, payload } = res.data
        if (result) {
          const productCatalog = payload.filter(({ code }) => code === 'PRODUCT')
          const applicationCatalog = payload.filter(({ code }) => code === 'APPLICATION')
          setProductCatalogData(productCatalog[0].catalogs)
          setApplicationCatalogData(applicationCatalog[0].catalogs)
          setIsLoad(true)
        }
      })
      .catch(() => renderSwalErrorAction())
  }

  useEffect(() => {
    getDownloadData()
  }, [])

  // 로딩중 화면
  if (isLoad === false) {
    return (
      <LoadingIndicator />
    )
  }
  return (
    <div className='DownloadCataloguesComponent'>
      {isLogin && (
        <DownloadAdminCreateComponent
          downloadType='Catalog'
          getDownloadData={getDownloadData}
        />
      )}
      <div className='DccMain'>
        {/* PRODUCT Catalog */}
        <DownloadTableComponent
          downloadType='Catalog'
          tableType='PRODUCT'
          tableData={productCatalogData}
          getDownloadData={getDownloadData}
        />

        {/* Application Catalog */}
        <DownloadTableComponent
          downloadType='Catalog'
          tableType='APPLICATION'
          tableData={applicationCatalogData}
          getDownloadData={getDownloadData}
        />
      </div>
    </div>
  )
}

export default DownloadCataloguesComponent
