/* eslint-disable */
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setNotice } from 'redux/actions'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const NoticeItem = ({ notice, history }) => {
  const dispatch = useDispatch()
  const {
    noticePk, title, createDate
  } = notice
  const date = new Date(createDate)
  const YEAR = date.getFullYear()
  const MONTH = ('0' + (date.getMonth() + 1)).slice(-2) 
  const DAY = ('0' + date.getDate()).slice(-2)
  const newItem = useMemo(() => {
    // 일주일 이내 생성된 공지사항 
    const crtDt = new Date(createDate)
    const today = new Date()
    today.setDate(today.getDate() - 7)
    if(today < crtDt) {
      return true
    }
    return false
  }, [])

  const handleClick = () => {
    dispatch(setNotice({ ...notice, action: 'UPDATE' }))
    history.push(`/notices/${notice.noticePk}`)
  }

  return (
    <TableRow key={noticePk} onClick={() => handleClick(notice, history, dispatch)}>
      <TableCell component='th' scope='row'>
        <span
          className='NoticeList__TitleSpan'
        >
          {title}
        </span>
        <span className='NoticeList__newImageBox'>
          {
            newItem
            ? <img
            className='NoticeList__newImage'
            src='images/new.png'
            alt='new-icon'
          />
            : ''
          }
        </span>
      </TableCell>
      <TableCell align='right'><span className='NoticeList__CreateDate'>{YEAR}.{MONTH}.{DAY}</span></TableCell>
    </TableRow>
  )
}

export default NoticeItem
