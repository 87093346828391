import React from 'react'
import ContactMapComponent from './ContactMapComponent'
import './ContactComponent.scss'

function ContactComponent() {
  return (
    <div className='ContactComponent'>
      <h2>오시는 길</h2>
      <h4>서울특별시 금천구 가산디지털1로 212, 코오롱디지털타워애스턴 505호</h4>
      <p><span>TEL</span> 02-851-4845</p>
      <p><span>FAX</span> 02-851-4850</p>
      <p><span>CONTACT</span> sales@huton.co.kr</p>
      <ContactMapComponent />
      <div className='SubwayBlock'>
        <h3>지하철 정보</h3>
        <div className='SubwayInfo'>
          <img src='/images/subway-line7.png' alt='지하철 7호선 가산디지털단지역' />
          <div className='SubwayInfo__Text'>
            <p><span>6번 출구</span>에서 <span>400m</span> 거리</p>
            <p>도보로 약 6분 소요</p>
          </div>
        </div>
        <div className='SubwayInfo'>
          <img src='/images/subway-line1.png' alt='지하철 1호선 가산디지털단지역' />
          <div className='SubwayInfo__Text'>
            <p><span>7번 출구</span>에서 <span>450m</span> 거리</p>
            <p>도보로 약 7분 소요</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ContactComponent
