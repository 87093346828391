/* eslint-disable */
// libraires
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import { swalAction } from 'constants/methods'

// constants
import { DEFAULT_URL, HISTORY_URL, HEADERS } from 'apis/restApis'

// material-ui
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// CSS
import './HistoryAdminEditComponent.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const { HISTORY_DELETE, HISTORY_UPDATE } = HISTORY_URL

const HistoryAdminEditComponent = ({
  historyPk, startYear, endYear, month, content, yearList, getHistoryData,
}) => {
  const authToken = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  // 수정할 데이터 저장할 변수
  const [newStartYear, setNewStartYear] = useState(startYear)
  const [newEndYear, setNewEndYear] = useState(endYear)
  const [newMonth, setNewMonth] = useState(month)
  const [newContent, setNewContent] = useState(content)


  // 수정시 modal 관련 변수, 메소드
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // content 삭제하기
  const handleDelete = () => {
    const url = `${DEFAULT_URL}${HISTORY_DELETE}`
    axios({ url, method: 'post', headers, data: [historyPk] })
      .then(res => {
        if (res.data.result) {
          Swal.fire('삭제되었습니다.')
          getHistoryData()
        }
      })
      .catch(() => Swal.fire('삭제를 실패하였습니다.'))
  }
  const handleDeleteWithSwal = () => {
    swalAction('정말로 삭제하시겠습니까?', '삭 제', handleDelete)
  }

  // content 수정하기
  const handleEdit = () => {
    const url = `${DEFAULT_URL}${HISTORY_UPDATE}`
    const data = [{
      historyPk: historyPk,
      startYear: newStartYear,
      endYear: newEndYear,
      month: newMonth,
      content: newContent,
    }]
    handleClose()
    axios({ url, method: 'post', headers, data })
      .then(res => {
        if (newEndYear !== 0 && newStartYear > newEndYear) {
          return Swal.fire('연도를 확인해주세요.')
        }
        if (newEndYear === newStartYear) {
          return Swal.fire('연도가 동일합니다.')
        }
        if (res.data.result) {
          Swal.fire('수정되었습니다.')
          getHistoryData()
        }
      })
      .catch(() => Swal.fire('수정을 실패하였습니다.'))
  }

  // 연도, 월 선택 리스트 보여주기
  const showMenuItem = menus => menus.map(menu => {
    return <MenuItem value={menu}>{menu}</MenuItem>
  })

  return (
    <div className='HistoryAdminEditComponent'>
      <span>
        <EditRoundedIcon
          onClick={handleOpen}
          className='HistoryAdminEditComponent__Icon'
        />
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className='Modal'
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className='Modal__Inside'>
              <div className='Modal__Inside__Icon'>
                <CloseIcon onClick={handleClose} />
              </div>
              <h2
                id='transition-modal-title'
                className='Modal__Inside__Title'
              >
                연혁 상세 수정
              </h2>
              <div id='transition-modal-description'>
                <div className='Modal__Inside__Input'>
                  <FormControl required>
                    <InputLabel id='demo-mutiple-name-label'>시작 연도</InputLabel>
                    <Select
                      labelId='demo-mutiple-name-label'
                      id='demo-mutiple-name'
                      value={newStartYear}
                      onChange={e => setNewStartYear(e.target.value)}
                      helperText='필수'
                      MenuProps={MenuProps}
                    >
                      {showMenuItem(yearList)}
                    </Select>
                  </FormControl>
                  {endYear !== 0
                    ?
                    <FormControl>
                      <InputLabel id='demo-mutiple-name-label'>종료 연도</InputLabel>
                      <Select
                        labelId='demo-mutiple-name-label'
                        id='demo-mutiple-name'
                        value={newEndYear}
                        onChange={e => setNewEndYear(e.target.value)}
                        MenuProps={MenuProps}
                        placeholder='선택'
                      >
                        {showMenuItem(yearList)}
                      </Select>
                    </FormControl>
                    : <> </>
                  } <TextField
                    required
                    id='standard-select-currency'
                    select
                    label='월'
                    value={newMonth}
                    onChange={e => setNewMonth(e.target.value)}
                    helperText='선택'
                  >
                    {showMenuItem([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
                  </TextField>
                  <TextField
                    required
                    id='standard-textarea'
                    fullWidth
                    label='연혁 내용'
                    placeholder='필수'
                    multiline
                    value={newContent}
                    onChange={e => setNewContent(e.target.value)}
                  />
                  <div className='Modal__Inside__Button'>
                    <Button
                      variant='contained'
                      onClick={() => handleEdit()}
                    >
                      저장하기
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <DeleteForeverRoundedIcon
          color='secondary'
          className='HistoryAdminEditComponent__Icon'
          onClick={() => handleDeleteWithSwal()}
        />
      </span>
      <hr />
    </div>
  )
}

export default HistoryAdminEditComponent
