import React from 'react'
import { Link } from 'react-router-dom'
import './ProductComponent.scss'

import { TEMP_DEFAULT_URL } from 'apis/restApis'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

const tempProductImage = '/images/product_temp_image.jpg'

function ProductComponent({ selectedCategories }) {
  function toTheTop() {
    const scrollLocation = document.documentElement.scrollTop
    if (scrollLocation > 380) {
      window.scrollTo(0, 380)
    }
  }

  return (
    <div className='ProductComponents'>

      <div className='ProductMain'>
        {selectedCategories?.map(({ productCategoryName, payload: products }) => (
          <div className='ProductCategories' key={productCategoryName}>
            <p className='ProductCagtoryName'>
              {productCategoryName}
            </p>

            <div className='ProductsCardBoard'>
              {products.map(product => {
                const linkPath = '/products/' + product.productPk // eslint-disable-line
                return (
                  <div 
                    className='ProductCard'
                    key={product.productPk}
                  >
                    <Link className='ProductLink' to={linkPath}>
                      <Button onClick={() =>
                        toTheTop()}
                      >
                        <Paper className='ProductPaper' elevation={2}>
                          <p className='ProductName' variant='h6'>{product.productName}</p>
                          <div className='ProductImageWrap'>
                            {product.productFile[0]
                              ? <img src={TEMP_DEFAULT_URL + product.productFile[0].url} alt='Product' />
                              : <img src={tempProductImage} alt='TempProduct' />}
                          </div>
                        </Paper>
                      </Button>
                    </Link>
                  </div>
                )
              })}
            </div>
            <hr className='productCategoryHr' />
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(ProductComponent)
