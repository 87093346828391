import React from 'react'

import './LoadingIndicator.scss'

const LoadingIndicator = ({ width = 200, height = 200 }) => (
  <div className='LoadingIndicator'>
    <img
      className='LoadingIndicator__Image'
      style={{ width, height }}
      alt='loading'
      src='/images/loading-icon.png'
    />
  </div>
)

export default LoadingIndicator
