import Swal from 'sweetalert2'

export const swalAction = (title, confirmButtonText, callbackFunc) => {
  Swal.fire({
    title,
    confirmButtonText,
    cancelButtonText: '취소',
    showCancelButton: true,
  }).then(result => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      callbackFunc()
    }
  })
}
