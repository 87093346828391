export const USER_STATE = {
  USER_LOGGEDIN: true,
  USER_NOT_LOGGEDIN: false,
}

export const DEFAULT_PRODUCTS = [
  {
    upperCategoryPk: 1,
    upperCategoryName: 'TestUpperCategory',
    payload: [
      {
        productCategoryPk: 2,
        productCategoryName: 'TestCategory',
        payload: [
          {
            upperCategoryPk: 1,
            productCategoryPk: 2,
            productPk: 3,
            productName: '',
            overview: '',
            keyFeature: '',
            inputSpec: '',
            outputSpec: '',
            dataProcessing: '',
            physicalPower: '',
            etc: '',
            productCategoryName: '',
            upperCategoryName: '',
          },
        ],
      },
    ],
  },
]

export const DEFAULT_PRODUCT = {
  productCategoryPk: -1,
  productName: '',
  overview: '',
  keyFeature: '',
  inputSpec: '',
  outputSpec: '',
  dataProcessing: '',
  physicalPower: '',
  etc: '',
}

export const TAB_TYPE = {
  PRODUCTS: 1,
  SYSTEMS: 2,
  CATEGORIES: 3,
  FILES: 4,
}

export const PRODUCT_STATE = {
  CREATE: 0,
  UPDATE: 1,
  DEFAULT: 2,
}

export const APPLICATION_STATE = {
  CREATE: 0,
  UPDATE: 1,
  DEFAULT: 2,
}

export const DEFAULT_CATEGORY = {
  productCategoryName: '',
  productCategoryPk: -1,
  upperCategoryName: '',
  upperCategoryPk: -1, // 상위카테고리는 무조건 -1
}

export const CATEGORY_STATE = {
  CREATE: 1,
  UPDATE: 2,
}
