import React from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import './SupportPage.scss'

import SecondNavigationBar from 'components/SecondNavigationBar'
import NoticeListComponent from 'components/Support/NoticeListComponent'
import DownloadComponent from 'components/Support/DownloadComponent'
import NoticeDetailComponent from 'components/Support/NoticeDetailComponent'
import DownloadCataloguesComponent from 'components/Support/DownloadCataloguesComponent'
import DownloadManualsComponent from 'components/Support/DownloadManualsComponent'

function SupportPage({ location }) {
  return (
    <div>
      <SecondNavigationBar pathname={location.pathname} />
      <SupportPageLNB pathname={location.pathname} />
      <Switch>
        <Route exact path='/support' component={NoticeListComponent} />
        <Route exact path='/support/download' component={DownloadComponent} />
        <Route path='/support/download/catalogues' component={DownloadCataloguesComponent} />
        <Route path='/support/download/manuals' component={DownloadManualsComponent} />
        <Route path='/support/:id' component={NoticeDetailComponent} />
      </Switch>
    </div>
  )
}

const SupportPageLNB = ({ pathname }) => (
  <div className='LinkBox'>
    <Link
      to='/support'
      className={pathname === '/support' ? 'LinkBox__Link__Select' : 'LinkBox__Link'}
    >
      공지사항
    </Link>
    <Link
      to='/support/download'
      className={pathname.includes('/download') ? 'LinkBox__Link__Select' : 'LinkBox__Link'}
    >
      다운로드
    </Link>
  </div>
)

export default SupportPage
