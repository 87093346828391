import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'redux/store'
import Route from './Route'
import './index.scss'

const listener = () => {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Route />
        </PersistGate>
      </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
  )
}

store.subscribe(listener)
listener()
