import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import classNames from 'classnames'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import ClearIcon from '@material-ui/icons/Clear'

import LoadingIndicator from 'components/LoadingIndicator'
import { swalAction } from 'constants/methods'
import { DEFAULT_URL, HEADERS, ADMIN_URLS } from 'apis/restApis'
import { DEFAULT_PRODUCTS, DEFAULT_CATEGORY, CATEGORY_STATE } from 'constants/constants_pb'

import './AdminCategory.scss'

const DEFAULT_UPPER_CATEGORY = DEFAULT_PRODUCTS[0]
const DEFAULT_LOWER_CATEGORY = DEFAULT_UPPER_CATEGORY.payload[0]

const {
  GET_CATEGORIES,
  UPDATE_CATEGORIES,
  DELETE_CATEGORIES,
  CREATE_CATEGORIES,
} = ADMIN_URLS

const {
  CREATE,
  UPDATE,
} = CATEGORY_STATE

const AdminCategory = () => {
  // token
  const token = useSelector(state => state.user.token)
  const { token: authToken } = token
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  // categories
  const [categories, setCategories] = useState(null)
  const [selectedUpperCategory, setSelectedUpperCategory] = useState(DEFAULT_UPPER_CATEGORY)
  const [selectedLowerCategory, setSelectedLowerCategory] = useState(DEFAULT_LOWER_CATEGORY)
  const [category, setCategory] = useState(DEFAULT_CATEGORY)
  const [categoryState, setCategoryState] = useState(CREATE)

  // input values
  const [inputText, setInputText] = useState('')
  const [renderInput, setRenderInput] = useState(false)
  const [inputLabelText, setInputLabelText] = useState('')

  // target to scroll
  const scrollToTarget = e => {
    if (e.pageY) {
      window.scrollTo(0, e.pageY)
    } else {
      const offsetY = document.documentElement.scrollTop
      const pageY = e.clientY + offsetY
      window.scrollTo(0, pageY)
    }
  }

  const getCategories = () => {
    const url = `${DEFAULT_URL}${GET_CATEGORIES}`
    axios.get(url, null, HEADERS)
      .then(({ data }) => {
        const { result, payload } = data
        // fast fail
        if (!result) {
          return Swal.fire('error')
        }
        const upperCategories = payload
          .filter(elem => elem.upperCategoryPk === -1)
          .map(elem => ({ ...elem, payload: [] }))
          .map(upper => {
            const lowerCategories = payload
              .filter(lower => lower.upperCategoryPk === upper.productCategoryPk)
            return { ...upper, payload: lowerCategories }
          })
        setCategories(upperCategories)
        setSelectedUpperCategory(upperCategories[0])
        setSelectedLowerCategory(DEFAULT_CATEGORY)
        setCategory(upperCategories[0])
        setRenderInput(false)
      })
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  const handleCreate = () => {
    const url = `${DEFAULT_URL}${CREATE_CATEGORIES}`
    // TODO: category의 productCategoryPk가 -1 이면 상위제품군, 아니면 하위 제품군
    const params = {
      productCategoryName: inputText,
      upperCategoryName: category.productCategoryName,
      upperCategoryPk: category.upperCategoryPk,
    }

    axios({ url, headers, params, method: 'post' })
      .then(res => {
        const { data: { payload } } = res
        Swal.fire(payload)
        setRenderInput(false)
        getCategories()
      })
      .catch(() => Swal.fire('카테고리 생성에 실패하였습니다.'))
  }

  const handleDelete = () => {
    const url = `${DEFAULT_URL}${DELETE_CATEGORIES}/${category.productCategoryPk}`
    axios({ url, headers, params: category, method: 'post' })
      .then(res => {
        const { data: { payload } } = res
        Swal.fire(payload)
        setRenderInput(false)
        getCategories()
      })
      .catch(() => Swal.fire('카테고리 삭제에 실패하였습니다.'))
  }

  const handleUpdate = () => {
    const url = `${DEFAULT_URL}${UPDATE_CATEGORIES}/${category.productCategoryPk}`
    const params = {
      productCategoryName: inputText,
      upperCategoryName: category.productCategoryName,
      upperCategoryPk: category.upperCategoryPk,
    }
    axios({ url, headers, params, method: 'post' })
      .then(res => {
        const { data: { payload } } = res
        Swal.fire(payload)
        setRenderInput(false)
        getCategories()
      })
      .catch(() => Swal.fire('카테고리 수정에 실패하였습니다.'))
  }

  const handleAction = () => {
    if (categoryState === CREATE) {
      handleCreate()
    } else {
      handleUpdate()
    }
  }

  const handleActionWithSwal = () => {
    swalAction('저장하시겠습니까?', '저 장', handleAction)
  }

  const handleDeleteWithSwal = () => {
    swalAction('정말로 삭제하시겠습니까?', '삭 제', handleDelete)
  }

  useEffect(() => {
    getCategories()
  }, [])

  // fast fail
  if (categories == null) {
    return <LoadingIndicator />
  }

  return (
    <div className='AdminProducts'>
      <h3 className='AdminProducts__ProductsBoxTitle'>PRODUCT GROUP 관리</h3>
      <div className='AdminProducts__ProductsBox'>
        <div className='AdminProducts__UpperCategoryBox'>
          <h4 className='AdminProducts__UpperCategoryTitle'>
            상위 PRODUCT 카테고리
          </h4>
          {categories.map(({
            productCategoryPk,
            productCategoryName,
            upperCategoryName,
            upperCategoryPk,
            payload,
          }) => (
            <div
              className={classNames('AdminProducts__UpperCategory', {
                clicked: productCategoryPk === selectedUpperCategory.productCategoryPk,
              })}
              key={productCategoryPk}
              onClick={() => {
                setSelectedUpperCategory({
                  productCategoryPk,
                  productCategoryName,
                  payload,
                })
                setSelectedLowerCategory(DEFAULT_CATEGORY)
                setCategory({
                  productCategoryPk,
                  productCategoryName,
                  upperCategoryName,
                  upperCategoryPk,
                })
                setRenderInput(false)
              }}
            >
              {productCategoryName}
            </div>
          ))}
        </div>
        <div className='AdminProducts__CategoryBox'>
          <h4 className='AdminProducts__CategoryTitle'>
            하위 PRODUCT 카테고리
          </h4>
          {selectedUpperCategory.payload.map(({
            productCategoryPk,
            productCategoryName,
            upperCategoryName,
            upperCategoryPk,
          }) => (
            <div
              className={classNames('AdminProducts__Category', {
                clicked: productCategoryPk === selectedLowerCategory.productCategoryPk,
              })}
              key={productCategoryPk}
              onClick={() => {
                setSelectedLowerCategory({
                  productCategoryPk,
                  productCategoryName,

                })
                setCategory({
                  productCategoryPk,
                  productCategoryName,
                  upperCategoryName,
                  upperCategoryPk,
                })
                setRenderInput(false)
              }}
            >
              {productCategoryName}
            </div>
          ))}
        </div>
      </div>
      <div className='AdminCateogry__CreateProductTab'>
        <div className='AdminCategory__InnerTab'>
          <h4 className='AdminCategory__TabTitle'>
            생성
          </h4>
          <Button
            className='AdminCategory__Button'
            type='submit'
            onClick={e => {
              setCategory(prev => ({
                ...prev,
                upperCategoryName: 'none',
                upperCategoryPk: -1,
              }))
              setCategoryState(CREATE)
              setInputText('')
              setRenderInput(true)
              setInputLabelText('새로운 상위 제품군 ')
              scrollToTarget(e)
            }}
          >
            <AddCircleIcon />
            새로운 상위 제품군 생성하기
          </Button>
          <Button
            className='AdminCategory__Button'
            type='submit'
            onClick={e => {
              setCategory(prev => ({
                ...prev,
                upperCategoryName: selectedUpperCategory.productCategoryName,
                upperCategoryPk: selectedUpperCategory.productCategoryPk,
              }))
              setCategoryState(CREATE)
              setInputText('')
              setRenderInput(true)
              setInputLabelText('하위 제품군 생성용 ')
              scrollToTarget(e)
            }}
          >
            <AddCircleIcon />
            {selectedUpperCategory.productCategoryName}&nbsp;의 하위 카테고리 생성하기
          </Button>
        </div>
        <div className='AdminCategory__InnerTab'>
          <h4 className='AdminCategory__TabTitle'>
            수정 및 삭제
          </h4>
          <Button
            className='AdminCategory__Button'
            type='submit'
            onClick={e => {
              setCategoryState(UPDATE)
              setInputText(category.productCategoryName)
              setRenderInput(true)
              setInputLabelText('하위 제품군 수정용 ')
              scrollToTarget(e)
            }}
          >
            <EditRoundedIcon color='primary' />
            {category.productCategoryName}&nbsp;카테고리 수정하기
          </Button>
          <Button
            className='AdminCategory__Button'
            type='submit'
            onClick={() => handleDeleteWithSwal()}
          >
            <DeleteForeverRoundedIcon color='secondary' />
            {category.productCategoryName}&nbsp;카테고리 삭제하기
          </Button>
        </div>
      </div>

      {renderInput && (
        <div className='AdminCategory__InputTab'>
          <hr />
          <div className='AdminCategory__UpperArea'>
            <h4 className='AdminCategory__InputTitle'>
              {inputLabelText}입력란
            </h4>
            <Button
              type='submit'
              onClick={() => {
                setInputText('')
                setRenderInput(false)
              }}
            >
              <ClearIcon />
            </Button>
          </div>
          <TextField
            id='outlined-textarea'
            placeholder={inputLabelText}
            variant='outlined'
            value={inputText}
            onChange={e => setInputText(e.target.value)}
          />
          <div className='AdminCategory__InputButton'>
            <Button
              color='primary'
              type='submit'
              variant='contained'
              onClick={() => handleActionWithSwal()}
            >
              저장
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminCategory
