// libraries
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'

// components
import LoadingIndicator from 'components/LoadingIndicator'
import DownloadAdminCreateComponent from 'components/Support/DownloadAdminCreateComponent'
import DownloadTableComponent from 'components/Support/DownloadTableComponent'

// constants
import { DEFAULT_URL, DOWNLOAD_URL } from 'apis/restApis'

// CSS
import './DownloadManualsComponent.scss'

const MANUAL_URL = `${DEFAULT_URL}${DOWNLOAD_URL.MANUALS}`

const renderSwalErrorAction = () => {
  Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요')
}

function DownloadManualsComponent() {
  const [productManualData, setProductManualData] = useState([])
  const [applicationManualData, setApplicationManualData] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  // 관리자의 로그인 여부를 확인함
  const isLogin = useSelector(state => state.isLogin)

  // 다운로드 페이지 메뉴얼 데이터 조회
  const getDownloadData = () => {
    axios.get(MANUAL_URL)
      .then(res => {
        const { result, payload } = res.data
        if (result) {
          const productManual = payload.filter(({ code }) => code === 'PRODUCT')
          const applicationManual = payload.filter(({ code }) => code === 'APPLICATION')
          setProductManualData(productManual[0].manuals)
          setApplicationManualData(applicationManual[0].manuals)
          setIsLoad(true)
        }
      })
      .catch(() => renderSwalErrorAction())
  }

  useEffect(() => {
    getDownloadData()
  }, [])

  // 로딩중 화면
  if (isLoad === false) {
    return (
      <LoadingIndicator />
    )
  }

  return (
    <div className='DownloadManualsComponent'>
      {isLogin && (
        <DownloadAdminCreateComponent
          downloadType='Manual'
          getDownloadData={getDownloadData}
        />
      )}

      <div className='DccMain'>
        {/* PRODUCT Manual */}
        <DownloadTableComponent
          downloadType='Manual'
          tableType='PRODUCT'
          tableData={productManualData}
          getDownloadData={getDownloadData}
        />
        {/* APPLICATION Manual */}
        <DownloadTableComponent
          downloadType='Manual'
          tableType='APPLICATION'
          tableData={applicationManualData}
          getDownloadData={getDownloadData}
        />

      </div>
    </div>
  )
}

export default DownloadManualsComponent
