import React from 'react'
import { Link } from 'react-router-dom'
import { linkTitles } from 'translations/ko'

import './Footer.scss'

const {
  home, introduce, products, applications, support,
} = linkTitles

function Footer() {
  return (
    <div className='Footer'>
      <div className='Footer__LinkBox'>
        <Link
          to='/'
          className='Footer__Link'
        >
          {home.linkText}
        </Link>
        <Link
          to='/introduce'
          className='Footer__Link'
        >
          {introduce.linkText}
        </Link>
        <Link
          to='/products'
          className='Footer__Link'
        >
          {products.linkText}
        </Link>
        <Link
          to='/applications'
          className='Footer__Link'
        >
          {applications.linkText}
        </Link>
        <Link
          to='/support'
          className='Footer__Link'
        >
          {support.linkText}
        </Link>
      </div>
      <hr />
      <div className='Footer__Info'>
        <h3>주식회사 휴톤</h3>
        <h4>
          <div>서울특별시 금천구 가산디지털1로 212, 코오롱디지털타워애스턴 505호</div>
          <div>TEL  02-851-4845</div>
          <div>FAX  02-851-4850</div>
          <div>CONTACT sales@huton.co.kr</div>
        </h4>
        <h4>Copyright © 2021 by HUTON. All rights reserved.</h4>
      </div>
    </div>
  )
}

export default Footer
