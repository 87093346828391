import React, { useEffect } from 'react'
import { linkTitles, links } from 'translations/ko'

import './SecondNavigationBar.scss'

const {
  INTRODUCE, PRODUCTS, APPLICATIONS, SUPPORT, NOTICE,
} = links

const {
  home: { logoText: homeLogoText },
  introduce: { logoText: introduceLogoText },
  products: { logoText: productsLogoText },
  applications: { logoText: applicationsLogoText },
  support: { logoText: supportLogoText },
  notice: { logoText: noticeLogoText },
} = linkTitles

const selectLogoMsg = currentUrl => {
  let titleText = homeLogoText
  if (currentUrl == null) {
    return titleText
  }

  if (currentUrl.includes(INTRODUCE)) {
    titleText = introduceLogoText
  } else if (currentUrl.includes(PRODUCTS)) {
    titleText = productsLogoText
  } else if (currentUrl.includes(APPLICATIONS)) {
    titleText = applicationsLogoText
  } else if (currentUrl.includes(SUPPORT)) {
    titleText = supportLogoText
  } else if (currentUrl.includes(NOTICE)) {
    titleText = noticeLogoText
  }

  return titleText
}

const SecondNavigationBar = ({ pathname }) => {
  const logoMsg = selectLogoMsg(pathname)
  useEffect(() => { window.scrollTo(0, 0) }, [pathname])

  return (
    <div className='SecondNavigationBar'>
      <div className='SecondNavigationBar__BG'>
        <h1 className='SecondNavigationBar__SubTitle'>
          {logoMsg}
        </h1>
      </div>
    </div>
  )
}

export default SecondNavigationBar
