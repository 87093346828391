import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SET_NOTICE, SET_USER, SET_LOGIN, SET_PRODUCT } from './actionTypes'

const initialState = {
  notice: null,
  user: {
    username: '',
    token: '',
  },
  isLogin: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE: {
      const { notice } = action.payload
      return { ...state, notice }
    }
    case SET_USER: {
      const user = action.payload
      return { ...state, user }
    }
    case SET_LOGIN:
      return { ...state, isLogin: !state.isLogin }
    case SET_PRODUCT: {
      const product = action.payload
      return { ...state, product }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'root',
  storage,
}

export default persistReducer(persistConfig, reducer)
