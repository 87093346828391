/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import SecondNavigation from 'components/SecondNavigationBar'
import LoadingIndicator from 'components/LoadingIndicator'
import { Editor } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor.css' /* eslint-disable-line */
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import 'tui-color-picker/dist/tui-color-picker.css' /* eslint-disable-line */
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css' 
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { DEFAULT_URL, SUPPORT_URL, HEADERS } from 'apis/restApis'

import './NoticeForm.scss'

const { NOTICE, NOTICE_UPDATE, NOTICE_CREATE } = SUPPORT_URL

const getPureNotice = notice => {
  const result = Object.entries(notice).reduce((acc, cur) => {
    const key = cur[0]
    const value = cur[1]
    if (key !== 'action' && key !== 'createDate') {
      acc[key] = value
    }
    return acc
  }, {})

  return result
}

// Custom Editor Toolbar
const customToolbar = [
  ['heading', 'bold', 'italic'],
  ['hr'],
  ['ul', 'ol'],
]

const NoticeForm = ({ history }) => {
  // history 에 따른 수정 또는 생성 분기 필요
  const notice = useSelector(state => state.notice)
  const isLogin = useSelector(state => state.isLogin)
  const authToken = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  // Editor Select
  const editorRef = useRef()

  // Data
  const [selectedNotice, setSelectedNotice] = useState(notice)

  // get Data
  const getNotice = () => {
    const url = `${DEFAULT_URL}${NOTICE}/${notice.noticePk}`
    axios.get(url)
      .then(res => {
        const { result, payload } = res.data
        // fast fail
        if (!result) {
          return null
        }

        setSelectedNotice(payload)
        // set content for initial load
        editorRef.current.getInstance().setHTML(payload.content, false)
      })
      .catch(() => Swal.fire('에러가 발생했습니다. 다시 시도해 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
  }

  // send Data
  const sendNoticeToServer = () => {
    setSelectedNotice(notice)
    const { action } = notice
    switch (action) {
      case 'UPDATE': {
        const { noticePk } = selectedNotice
        const url = `${DEFAULT_URL}${NOTICE_UPDATE}/${noticePk}`
        const data = getPureNotice(selectedNotice)
        axios({ url, method: 'post', headers, data })
          .then(res => {
            const { data: { result } } = res

            if (result) {
              Swal.fire('수정 완료')
              history.push('/support')
            }
          })
          .catch(() => Swal.fire('에러가 발생했습니다. 다시 시도해 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
        break
      }
      case 'CREATE':
      default: {
        const url = `${DEFAULT_URL}${NOTICE_CREATE}`
        const data = getPureNotice(selectedNotice)
        axios({ url, method: 'post', headers, data })
          .then(res => {
            const { data: { result } } = res

            if (result) {
              Swal.fire('생성 완료')
              history.push('/support')
            }
          })
          .catch(() => Swal.fire('에러가 발생했습니다. 다시 시도 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
      }
    }
  }

  useEffect(() => {
    if (notice.noticePk !== -1) {
      getNotice()
    }
  }, [])

  // 로그인하지 않았다면 뒤로돌려보냄
  if (!isLogin) {
    history.push('/')
  }

  // fast fail
  if (selectedNotice == null) {
    return (
      <div className='NoticeDetail'>
        <SecondNavigation />
        <div>
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  // 렌더링
  return (
    <div className='NoticeForm'>
      <SecondNavigation />
      <div className='NoticeForm__Form'>
        <h1 className='NoticeForm__Header'>공지사항 작성</h1>
        <div className='NoticeForm__TitleBox'>
          <div>제목</div>
          <input
            value={selectedNotice.title}
            onChange={e => setSelectedNotice(prev => ({ ...prev, title: e.target.value }))}
          />
        </div>
        <div className='NoticeForm__ContentBox'>
          <div>내용</div>

          <div className='NoticeForm__ContentEditorWrapper'>
            <Editor
              height='580px'
              usageStatistics={false}
              initialEditType='wysiwyg'
              toolbarItems={customToolbar}
              plugins={[colorSyntax]}
              onChange={() => {
                const innerText = editorRef.current.getInstance().getHTML()
                setSelectedNotice(prev => ({ ...prev, content: innerText }))
              }}
              ref={editorRef}
            />
          </div>

        </div>
        <div className='NoticeForm__ButtonBox'>
          {selectedNotice.content === '' || selectedNotice.title === '' ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => sendNoticeToServer()}
              disabled
            >
              저&nbsp;장
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              onClick={() => sendNoticeToServer()}
            >
              저&nbsp;장
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NoticeForm
