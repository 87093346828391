import React, { useState, useEffect } from 'react'
import CustomEditor from 'components/CustomEditor'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import classNames from 'classnames'
import { swalAction } from 'constants/methods'

import TextField from '@material-ui/core/TextField'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import PublishIcon from '@material-ui/icons/Publish'

import { DEFAULT_PRODUCT, DEFAULT_PRODUCTS, PRODUCT_STATE } from 'constants/constants_pb'
import { DEFAULT_URL, HEADERS, ADMIN_URLS } from 'apis/restApis'

import './AdminProducts.scss'
import './ImageForm.scss'

const {
  GET_PRODUCTS, UPDATE_PRODUCT, DELETE_PRODUCT, CREATE_PRODUCT, GET_CATEGORIES,
} = ADMIN_URLS

// const textToConvert = '^'
const { CREATE, UPDATE, DEFAULT } = PRODUCT_STATE
const DEFAULT_UPPER_CATEGORY = DEFAULT_PRODUCTS[0]
const DEFAULT_LOWER_CATEGORY = DEFAULT_UPPER_CATEGORY.payload[0]

const AdminProducts = () => {
  // token
  const token = useSelector(state => state.user.token)
  const { token: authToken } = token

  // products
  const [products, setProducts] = useState(DEFAULT_PRODUCTS)
  const [selectedUpperCategory, setSelectedUpperCategory] = useState(DEFAULT_UPPER_CATEGORY)
  const [selectedLowerCategory, setSelectedLowerCategory] = useState(DEFAULT_LOWER_CATEGORY)
  const [selectedProduct, setSelectedProduct] = useState(DEFAULT_PRODUCT)
  const [renderProduct, setRenderProduct] = useState(DEFAULT)
  const [showDeleteButton, setShowDeleteButton] = useState(true)
  const [file, setFile] = useState(null)
  const [originalName, setOriginalName] = useState(null)

  // Editor Setter
  const [crntKeyFeature, setCrntKeyFeature] = useState('')
  const [editorContent, setEditorContent] = useState('')

  const headers = {
    ...HEADERS,
    'X-AUTH-TOKEN': authToken,
  }

  const headersWithContentType = {
    ...headers,
    'Content-Type': 'multipart/form-data',
  }

  const buttonDisableConditionForFile = () => {
    if (renderProduct === CREATE) {
      return file == null
    }
    return originalName === null && file == null
  }

  const getProducts = () => {
    const url = `${DEFAULT_URL}${GET_PRODUCTS}`
    axios({ url, method: 'get' })
      .then(({ data }) => {
        const { result, payload } = data
        // fast fail
        if (!result) {
          return Swal.fire('error')
        }

        setProducts(payload)
        const { upperCategoryName, upperCategoryPk, payload: thisPayload } = payload[0]
        setSelectedUpperCategory({ upperCategoryName, upperCategoryPk, payload: thisPayload })
        setSelectedLowerCategory(thisPayload[0])
      })
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  const getProductsCategories = () => {
    const url = `${DEFAULT_URL}${GET_CATEGORIES}`

    axios({ url, method: 'get' })
      .then(res => {
        const { data: { payload: originalData } } = res
        const upperCateogries = originalData.reduce((acc, cur) => {
          const { upperCategoryPk, productCategoryPk } = cur
          if (upperCategoryPk === -1) {
            acc[productCategoryPk] = { ...cur, payload: [] }
          }
          return acc
        }, {})
        const lowerCategories = res.data.payload
          .filter(({ upperCategoryPk }) => upperCategoryPk !== -1)
        lowerCategories.forEach(elem => {
          const { upperCategoryPk } = elem
          if (upperCateogries[upperCategoryPk] != null) {
            upperCateogries[upperCategoryPk].payload.push(elem)
          }
        })
      })
      .catch(() => Swal.fire('해당 상품 데이터를 받아오는데 실패하였습니다.'))
  }

  const getProductDetail = productPk => {
    const url = `${DEFAULT_URL}${GET_PRODUCTS}/${productPk}`
    axios({ url, method: 'get' })
      .then(({ data }) => {
        const { result, payload: { product, productFile } } = data
        if (!result) {
          return Swal.fire('error')
        }

        setSelectedProduct(product)
        setRenderProduct(UPDATE)
        setOriginalName(productFile[0].originalName)
      })
      .catch(() => Swal.fire('해당 상품 데이터를 받아오는데 실패하였습니다.'))
  }

  const handleDelete = () => {
    const { productPk } = selectedProduct
    const url = `${DEFAULT_URL}${DELETE_PRODUCT}/${productPk}`
    axios({ url, method: 'post', headers })
      .then(res => {
        const { data: { result, payload } } = res
        if (result) {
          setSelectedProduct(DEFAULT_PRODUCT)
          setRenderProduct(DEFAULT)
          getProducts()
          Swal.fire(payload)
        }
      })
      .catch(() => Swal.fire('상품 삭제에 실패하였습니다.'))
  }

  const handleDeleteWithSwal = () => {
    swalAction('정말로 삭제하시겠습니까?', '삭제', () => handleDelete())
  }

  const handleUpdate = () => {
    const {
      contentHtml,
      keyFeature,
      productCategoryPk,
      productName,
      productPk, // this value is for url
    } = selectedProduct

    const url = `${DEFAULT_URL}${UPDATE_PRODUCT}/${productPk}`

    const data = new FormData()
    data.append('file', file)
    data.append('contentHtml', contentHtml)
    data.append('keyFeature', keyFeature)
    data.append('productCategoryPk', productCategoryPk)
    data.append('productName', productName)
    data.append('originalName', originalName)

    axios({ url, headers: headersWithContentType, method: 'post', data })
      .then(res => {
        const { data: { result, payload } } = res
        if (result) {
          setSelectedProduct(DEFAULT_PRODUCT)
          setRenderProduct(DEFAULT)
          getProducts()
          Swal.fire(payload)
        }
      })
      .catch(() => Swal.fire('수정에 실패하였습니다.'))
  }

  const handleCreate = () => {
    const {
      contentHtml,
      keyFeature,
      productName,
    } = selectedProduct
    const { productCategoryPk } = selectedLowerCategory
    const url = `${DEFAULT_URL}${CREATE_PRODUCT}`

    const data = new FormData()
    data.append('file', file)
    data.append('contentHtml', contentHtml)
    data.append('keyFeature', keyFeature)
    data.append('productCategoryPk', productCategoryPk)
    data.append('productName', productName)
    data.append('originalName', null)

    axios({ url, headers: headersWithContentType, method: 'post', data })
      .then(res => {
        const { data: { result, payload } } = res
        if (result) {
          Swal.fire(payload)
          setSelectedProduct(DEFAULT_PRODUCT)
          setRenderProduct(DEFAULT)
          getProducts()
        }
      })
      .catch(() => Swal.fire('생성에 실패하였습니다.'))
  }

  const handleSendButton = () => {
    if (renderProduct === CREATE) {
      handleCreate()
    } else {
      handleUpdate()
    }
  }

  const handleSendButtonWithSwal = () => {
    swalAction('저장하시겠습니까?', '저 장', handleSendButton)
  }

  // 삽입, 수정, 갱신이 일어날 때마다 제품들 새로 받아옴
  useEffect(() => {
    getProducts()
    getProductsCategories()
  }, [])

  // KeyFeature와 contentHtml의 변경 사항이 실시간으로 반영됨
  useEffect(() => {
    setSelectedProduct(prev => ({ ...prev, keyFeature: crntKeyFeature }))
  }, [crntKeyFeature])
  useEffect(() => {
    setSelectedProduct(prev => ({ ...prev, contentHtml: editorContent }))
  }, [editorContent])

  const renderImageForm = () => (
    <div className='AdminProducts__FileForm'>
      <form
        className='ImageForm__Form'
        onSubmit={e => { e.preventDefault() }}
        encType='multipart/form-data'
      >
        <Button variant='contained' ctype='submit'>
          <label
            className='ImageForm__Label'
            htmlFor='file-upload-input'
          >
            <PublishIcon />
            <span>{selectedProduct.productName} 이미지 파일 업로드</span>
            <input
              type='file'
              id='file-upload-input'
              name='file'
              accept='image/*'
              style={{ display: 'none' }}
              onChange={e => { setFile(e.target.files[0]) }}
            />
          </label>
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          disabled={buttonDisableConditionForFile()}
          onClick={() => {
            setFile(null)
            setOriginalName(null)
          }}
          className='ImageForm__DeleteButton'
        >
          파일 삭제
        </Button>
        <div className='ImageForm__Filename'>{file ? file.name : originalName}&nbsp;</div>
      </form>
    </div>
  )

  return (
    <div>
      <h3 className='AdminProducts__ProductsBoxTitle'>
        PRODUCT 관리
      </h3>
      <span className='AdminProducts__ProductsBoxSubTitle'>
        <CheckIcon />
        PRODUCT를 클릭하여 수정할 수 있습니다.
      </span>
      <div className='AdminProducts__ProductsBox'>
        <div className='AdminProducts__UpperCategoryBox'>
          <h4 className='AdminProducts__UpperCategoryTitle'>
            상위 PRODUCT 카테고리
          </h4>
          {products.map(({
            upperCategoryPk,
            upperCategoryName,
            payload,
          }) => (
            <div
              className={classNames('AdminProducts__UpperCategory', {
                clicked: upperCategoryPk === selectedUpperCategory.upperCategoryPk,
              })}
              key={upperCategoryPk}
              onClick={() => {
                setSelectedUpperCategory({
                  upperCategoryPk,
                  upperCategoryName,
                  payload,
                })
                setSelectedProduct(DEFAULT_PRODUCT)
                setSelectedLowerCategory(payload[0])
                setRenderProduct(DEFAULT)
              }}
            >
              {upperCategoryName}
            </div>
          ))}
        </div>
        <div className='AdminProducts__CategoryBox'>
          <h4 className='AdminProducts__CategoryTitle'>
            하위 PRODUCT 카테고리
          </h4>
          {selectedUpperCategory.payload.map(({
            productCategoryPk,
            productCategoryName,
            payload,
          }) => (
            <div
              className={classNames('AdminProducts__Category', {
                clicked: productCategoryPk === selectedLowerCategory.productCategoryPk,
              })}
              key={productCategoryPk}
              onClick={() => {
                setSelectedLowerCategory({
                  productCategoryPk,
                  productCategoryName,
                  payload,
                })
                setSelectedProduct(DEFAULT_PRODUCT)
                setRenderProduct(DEFAULT)
              }}
            >
              {productCategoryName}
            </div>
          ))}
        </div>
        <div className='AdminProducts__ProductBox'>
          <h4 className='AdminProducts__ProductTitle'>
            PRODUCT
          </h4>
          {selectedLowerCategory.payload.map(({
            productPk,
            productName,
          }) => (
            <div
              className={classNames('AdminProducts__Product', {
                clicked: productPk === selectedProduct.productPk,
              })}
              key={productPk}
              onClick={() => {
                getProductDetail(productPk)
                setRenderProduct(DEFAULT)
                setShowDeleteButton(true)
              }}
            >
              {productName}
            </div>
          ))}
        </div>
      </div>
      <div className='AdminProducts__CreateProductButtonBox'>
        {renderProduct !== UPDATE && (
          <Button
            className='AdminProducts__CreateProductButton'
            type='submit'
            onClick={() => {
              setRenderProduct(CREATE)
              setSelectedProduct({
                upperCategoryPk: selectedUpperCategory.upperCategoryPk,
                productName: '',
                overview: '',
                contentHtml: '',
              })
              setShowDeleteButton(false)
              setOriginalName(null)
            }}
          >
            <AddCircleIcon />
            {selectedLowerCategory.productCategoryName
              || selectedUpperCategory.upperCategoryName}
            &nbsp;의 새로운 PRODUCT 생성하기
          </Button>
        )}
      </div>
      {renderProduct !== DEFAULT && (
        <div className='AdminProducts__SelectedProducts'>
          {showDeleteButton && (
            <div className='AdminProducts__SubmitButtonBox'>
              <Button
                className='AdminProducts__SubmitButton'
                type='submit'
                onClick={() => handleDeleteWithSwal()}
              >
                <DeleteForeverRoundedIcon color='secondary' />삭제하기
              </Button>
            </div>
          )}
          <div className='AdminProducts__SelectedProduct'>
            <div><span>NAME</span></div>
            <TextField
              id='outlined-textarea'
              placeholder='NAME'
              multiline
              variant='outlined'
              value={selectedProduct.productName}
              onChange={e => {
                setSelectedProduct(prev => ({ ...prev, productName: e.target.value }))
              }}
            />
          </div>
          <div className='AdminProducts__Editor'>
            <div><span>KEY FEATURE</span></div>
            <CustomEditor
              initialContent={selectedProduct.keyFeature}
              setEditorContent={setCrntKeyFeature}
              height='250px'
            />
          </div>

          <div className='AdminProducts__Editor'>
            <div><span>PRODUCT 설명</span></div>
            <CustomEditor
              initialContent={selectedProduct.contentHtml}
              setEditorContent={setEditorContent}
              isProduct={true} /* eslint-disable-line */
            />
          </div>
          {renderImageForm()}
          <div className='AdminProducts__SubmitButtonBox'>
            <Button
              className='AdminProducts__SubmitButton'
              color='primary'
              variant='contained'
              type='submit'
              onClick={() => handleSendButtonWithSwal()}
            >
              <span>저장하기</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminProducts
