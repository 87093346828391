import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import Navbar and Footer
import AdminPage from 'pages/AdminPage'
import NavigationBar from 'pages/NavigationBar'
import Footer from 'pages/Footer'

// Pages
import HomePage from 'pages/HomePage'
import IntroducePage from 'pages/IntroducePage'
import ProductPage from 'pages/ProductPage'
import ApplicationPage from 'pages/ApplicationPage'
import SupportPage from 'pages/SupportPage'
import SearchMainPage from 'pages/SearchMainPage'
import PageNotFoundPage from 'pages/PageNotFoundPage'

// Components
import NoticeDetailComponent from 'components/Support/NoticeDetailComponent'
import NoticeForm from 'components/Support/NoticeForm'

const routes = (
  <>
    <NavigationBar />
    <Switch>
      <Route exact path='/' component={HomePage} />
      {/* Introduce Page */}
      <Route path='/introduce' component={IntroducePage} />

      {/* Products Page */}
      <Route path='/products' component={ProductPage} />

      {/* Application Page */}
      <Route path='/applications/:applicationPk' component={ApplicationPage} />
      <Route path='/applications' component={ApplicationPage} />

      {/* Support Page */}
      <Route path='/support' component={SupportPage} />
      <Route path='/notices/:noticePK' component={NoticeDetailComponent} />
      <Route path='/notices' component={NoticeForm} />

      {/* Admin Page */}
      <Route path='/admin' component={AdminPage} />

      {/* Search Page */}
      <Route path='/search' component={SearchMainPage} />

      {/* ATTENTION! ErrorPage should always be HERE */}
      <Route path='/:string' component={PageNotFoundPage} />
    </Switch>
    <Footer />
  </>
)

export default routes
