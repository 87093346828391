// libraries
import React, { useState, useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

// components
import SecondNavigation from 'components/SecondNavigationBar'
import SearchIcon from '@material-ui/icons/Search'
import SearchResultList from 'components/Search/SearchResultList'

// constants
import { DEFAULT_URL, SEARCH_URL, HEADERS } from 'apis/restApis'

// CSS
import './SearchPage.scss'

const { SEARCH, CATEGORY: { PRODUCT_URL, APPLICATION_URL } } = SEARCH_URL
const SEARCH_DEFAULT_URL = `${DEFAULT_URL}${SEARCH}`

const setDefaultData = categoryUrl => ({
  categoryUrl,
  results: [],
  page: 0,
})

const PRODUCT_DEFAULT_DATA = setDefaultData(PRODUCT_URL)
const APPLICATION_DEFAULT_DATA = setDefaultData(APPLICATION_URL)

const SearchMainPage = () => {
  // 입력창 관련
  const [inputText, setInputText] = useState('')

  // 데이터
  const [productsData, setProductsData] = useState(PRODUCT_DEFAULT_DATA)
  const [applicationsData, setApplicationsData] = useState(APPLICATION_DEFAULT_DATA)

  // 검색결과가 없음을 표시해주는 문구
  const [hasSearchPerformed, setHasSearchPerformed] = useState(false)

  // inputText의 변경을 감지
  const inputTextRef = useRef('')

  const isEmptyResultFromAllSearch = () => {
    const { results: productsDataResults } = productsData
    const { results: applicationsResults } = applicationsData
    const isResultsEmpty = productsDataResults.length === 0 && applicationsResults.length === 0
    return hasSearchPerformed && isResultsEmpty
  }

  const getSearchData = (data, setData) => {
    const { categoryUrl, page } = data
    const url = `${SEARCH_DEFAULT_URL}${categoryUrl}/?pageNum=${page}&keyword=${inputText}`
    axios({ url, headers: HEADERS, method: 'get' })
      .then(res => {
        const { data: { payload } } = res
        const newResults = [...data.results, ...payload[0]]
        setData({ categoryUrl, results: newResults, page: page + 1 })
        setHasSearchPerformed(true)
      })
      .catch(() => Swal.fire('인터넷 연결을 확인해주세요.'))
  }

  const getAllSearchData = () => {
    if (inputText === '') {
      return
    }

    const { current: prevInputText } = inputTextRef
    if (prevInputText !== inputText) {
      getSearchData(PRODUCT_DEFAULT_DATA, setProductsData)
      getSearchData(APPLICATION_DEFAULT_DATA, setApplicationsData)
    } else {
      getSearchData(productsData, setProductsData)
      getSearchData(applicationsData, setApplicationsData)
    }
  }

  return (
    <div className='SearchPage'>
      <SecondNavigation />
      <div className='SearchBar'>
        <h1 className='SearchBar__Text'>검색창</h1>
        <div className='SearchBar__Input'>
          <input
            type='text'
            value={inputText}
            onChange={e => setInputText(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                getAllSearchData()
              }
            }}
          />
          <SearchIcon
            className='SearchBar__Input__Icon'
            onClick={() => getAllSearchData()}
          />
        </div>
      </div>
      <div className='SearchPage__Result'>
        {!hasSearchPerformed && (
          <div>
            <h3 className='SearchPage__Result__Text'>검색어를 입력해주세요</h3>
          </div>
        )}
        {isEmptyResultFromAllSearch() && (
          <div>
            <h3 className='SearchPage__Result__Text'>검색 결과가 없습니다.</h3>
          </div>
        )}

        <SearchResultList
          mainTitle='PRODUCT'
          getSearchData={getSearchData}
          data={productsData}
          setData={setProductsData}
          inputText={inputText}
        />
        <SearchResultList
          mainTitle='APPLICATION'
          getSearchData={getSearchData}
          data={applicationsData}
          setData={setApplicationsData}
          inputText={inputText}
        />
      </div>
    </div>
  )
}

export default SearchMainPage
