/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'

import { setNotice } from 'redux/actions'
import LoadingIndicator from 'components/LoadingIndicator'
import { DEFAULT_URL, SUPPORT_URL } from 'apis/restApis'
import NoticeItem from './NoticeItem'

import './NoticeListComponent.scss'

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
})

const { NOTICE } = SUPPORT_URL
const DEFAULT_PAGE = 1
const DEFAULT_COUNT = 10

const NoticeListComponent = ({ history }) => {
  const classes = useStyles()

  // notices
  const [notices, setNotices] = useState(null)
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [count, setCount] = useState(DEFAULT_COUNT)
  // 관리자의 로그인 여부를 확인함
  const isLogin = useSelector(state => state.isLogin)
  // 게시글 생성하기를 누르는 경우 SET_NOTICE를 초기화
  const dispatch = useDispatch()

  const getNotices = () => {
    const URL = `${DEFAULT_URL}${NOTICE}?page=${page}`
    axios.get(URL)
      .then(res => {
        const { result, payload, count: noticeCount } = res.data
        if (!result) {
          Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요')
          return
        }
        setNotices(payload)
        setCount(Math.ceil(noticeCount / 10))
      })
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  useEffect(() => {
    getNotices()
  }, [])

  useEffect(() => {
    getNotices()
  }, [page])

  const handleCreateButton = () => {
    history.push(NOTICE)
    dispatch(setNotice({ title: '', content: '', action: 'CREATE', noticePk: -1 }))
  }

  const handleChange = (event, value) => {
    if (event && page !== value) {
      setPage(value)
    }
  }

  // 로딩중 화면
  if (notices == null) {
    return (
      <div className='NoticeList'>
        <h1 className='NoticeList__Header'>공지사항</h1>
        <div className='NoticeList__Table--empty'>
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  // 데이터가 있는 경우 렌더링
  return (
    <div className='NoticeList'>
      <h2 className='NoticeList__Header'>공지사항</h2>
      {isLogin && (
        <div className='NoticeList__ButtonBox'>
          <Button
            color='primary'
            variant='outlined'
            type='submit'
            onClick={() => handleCreateButton()}
          >
            게시글 생성
          </Button>
        </div>
      )}
      <div className='NoticeList__Table'>
        <TableContainer className='NoticeList__TableContainer' component={Paper}>
          <Table className={classes.table} aria-label='simple table' >
            <TableHead>
              <TableRow>
                <TableCell align='left'><span className='NoticeList__TableHead1'>제목</span></TableCell>
                <TableCell align='right'><span className='NoticeList__TableHead2'>생성일</span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notices.map(notice => (
                <NoticeItem
                  history={history}
                  notice={notice}
                  key={notice.noticePk}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='NoticeList__Pagination'>
          <Pagination
            count={count}
            page={page}
            // onClick={e => handleClick(e)}
            onChange={(e, v) => handleChange(e, v)}
          />
        </div>
      </div>
    </div>
  )
}

export default NoticeListComponent
