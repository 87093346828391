import React from 'react'
import Grid from '@material-ui/core/Grid'
import './BusinessPartnerComponent.scss'

const partner1 = [
  {
    company: 'CJ 헬로비전',
    img: '/images/BusinessPartner/CJ헬로비젼.jpg',
    url: 'http://lghellovision.net/main.do',
  },
  {
    company: 'KBS',
    img: '/images/BusinessPartner/kbs.jpg',
    url: 'https://www.kbs.co.kr/',
  },
  {
    company: 'kt',
    img: '/images/BusinessPartner/kt.png',
    url: 'https://www.kt.com/',
  },
  {
    company: 'LG U+',
    img: '/images/BusinessPartner/LG_U+.jpg',
    url: 'https://www.uplus.co.kr/home/Index.hpi',
  },
  {
    company: 'MBC',
    img: '/images/BusinessPartner/mbc.jpg',
    url: 'http://www.imbc.com/',
  },
  {
    company: 'SBS',
    img: '/images/BusinessPartner/sbs.png',
    url: 'https://www.sbs.co.kr/',
  },
  {
    company: 'SK broadband',
    img: '/images/BusinessPartner/SKBB.jpg',
    url: 'https://www.skbroadband.com/Main.do',
  },
  {
    company: 'ABN 아름 방송',
    img: '/images/BusinessPartner/아름방송.jpg',
    url: 'https://www.abn.co.kr/index.do',
  },
]

const partner2 = [
  {
    company: '한국정보통신기술협회',
    img: '/images/BusinessPartner/TTA.jpg',
    url: 'https://www.tta.or.kr/',
  },
  {
    company: '한국전자통신연구원',
    img: '/images/BusinessPartner/ETRI.png',
    url: 'https://www.etri.re.kr/intro.html',
  },
]

function BusinessPartnerComponent() {
  return (
    <div className='BusinessPartnerComponent'>
      <div className='BusinessPartnerComponent__Block'>
        <h2>방송 및 통신</h2>
        <Grid container>
          {partner1.map(({ company, img, url }) => (
            <Grid item md={4} xs={6}>
              <h4 className='BusinessPartnerComponent__Text'>{company}</h4>
              <a target='_blank' rel='noopener noreferrer' href={url}>
                <img className='BusinessPartnerComponent__Image' src={img} alt={company} />
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
      <hr />
      <div className='BusinessPartnerComponent__Block'>
        <h2>R&D</h2>
        <Grid container>
          {partner2.map(({ company, img, url }) => (
            <Grid item md={4} xs={6}>
              <h4 className='BusinessPartnerComponent__Text'>{company}</h4>
              <a target='_blank' rel='noopener noreferrer' href={url}>
                <img className='BusinessPartnerComponent__Image' src={img} alt={company} />
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default BusinessPartnerComponent
