import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import Swal from 'sweetalert2'

import axios from 'axios'

import SecondNavigationBar from 'components/SecondNavigationBar'
import LoadingIndicator from 'components/LoadingIndicator'

import { DEFAULT_URL, PRODUCT_URL, HEADERS } from 'apis/restApis'
import SideNavBar from 'components/Product/SideNavBar'
import ProductComponent from 'components/Product/ProductComponent'
import ProductDetailComponent from 'components/Product/ProductDetailComponent'

import './ProductPage.scss'
import ProductEditorComponent from 'components/Product/ProductEditorComponent'

const { BASE } = PRODUCT_URL

const productUrl = `${DEFAULT_URL}${BASE}`

function ProductPage({ location }) {
  // 전체 데이터 들어가는 리스트
  const [allInfo, setAllInfo] = useState([])
  // 제품군 선택 함수, 사이드 내브바 선택 스테이트
  const [selectedUpperCategory, setSelectedUpperCategory] = useState(0)
  // 선택한 카테고리
  // const { FIRST, SECOND, THIRD } = productsType
  const [categories, setCategories] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    axios.get(productUrl, null, HEADERS)
      .then(({ data }) => {
        if (data == null) {
          return null
        }
        const { result, payload } = data
        if (result !== true) {
          return null
        }
        // 대분류 포함 모든 데이터: allInfo
        const infoArray = payload.filter(({ upperCategoryName }) => upperCategoryName !== 'none')

        setAllInfo(infoArray)
        if (infoArray.length > 0) {
          setIsLoaded(true)
        }
      }, () => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }, [])

  useEffect(() => {
    if (isLoaded) {
      setCategories(allInfo[0].payload)
    }
  }, [isLoaded])

  useEffect(() => {
    if (allInfo[selectedUpperCategory] === undefined) {
      return null
    }
    setCategories(allInfo[selectedUpperCategory].payload)
  }, [selectedUpperCategory])

  if (!isLoaded) {
    return (
      <>
        <SecondNavigationBar pathname={location.pathname} />
        <div className='LoadingIndicator'>
          <LoadingIndicator />
        </div>
      </>
    )
  }

  return (
    <div className='ProductWrap'>
      <SecondNavigationBar pathname={location.pathname} />
      <div className='ProductPage'>

        <div className='ProductPage__SideNavDiv'>
          <Switch>
            <SideNavBar
              setSelectedUpperCategory={setSelectedUpperCategory}
              selectedUpperCategory={selectedUpperCategory}
              allInfo={allInfo}
            />
          </Switch>
        </div>

        <div className='ProductContent'>
          <Switch>
            <Route
              exact
              path='/products'
              render={() => (<ProductComponent selectedCategories={categories} />)}
            />
            <Route path='/products/create' component={ProductEditorComponent} />
            <Route
              path='/products/editor'
              component={ProductEditorComponent}
            />
            <Route path='/products/:id' component={ProductDetailComponent} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default ProductPage
