import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import './DownloadComponent.scss'

import Paper from '@material-ui/core/Paper'

function DownloadComponent() {
  // 관리자의 로그인 여부를 확인함
  const isLogin = useSelector(state => state.isLogin)

  return (
    <div className='DownloadPage'>
      <div className='DownloadPage__Admin'>
        {isLogin && (
          <h3>
            Catalog, Manual 각각의 화면에서 관리 가능합니다.
          </h3>
        )}
      </div>
      <div className='PaperMain'>

        {/* 카탈로그 카드 */}
        <div className='CardDiv'>
          <Link className='CardLink' to='/support/download/catalogues'>
            <Paper className='CardPaper' elevation={3}>
              <div className='CardBodyWrap'>

                <div className='CardTitleDiv'>
                  <p className='CardTitleText'>
                    Catalog
                  </p>
                </div>

                <div className='CardBodyDiv'>
                  <p className='CardBodyText'>
                    <span>제품별 카탈로그</span><br /> 다운로드 페이지입니다.
                  </p>
                </div>
              </div>
            </Paper>
          </Link>
        </div>

        {/* 매뉴얼 카드 */}
        <div className='CardDiv'>
          <Link className='CardLink' to='/support/download/manuals'>
            <Paper className='CardPaper' elevation={3}>
              <div className='CardBodyWrap'>
                <div className='CardTitleDiv'>
                  <p className='CardTitleText'>
                    Manual
                  </p>
                </div>
                <div className='CardBodyDiv'>
                  <p className='CardBodyText'>
                    <span>제품별 사용설명서</span><br /> 다운로드 페이지입니다.
                  </p>
                </div>
              </div>
            </Paper>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DownloadComponent
