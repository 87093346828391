import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

import './HomeIntroduceBlock.scss'

function HomeIntroduceBlock() {
  return (
    <div>
      <div className='HomeIntroduceBlock'>
        <img
          className='HomeIntroduceBlock__Image'
          src='images/home-company-building.jpg'
          alt='company-introduce'
        />
        <div className='HomeIntroduceBlock__Content'>
          <span className='Title'>
            <div>
              <span className='Title__Blue'>HU</span>
              MAN
            </div>
            <div>
              <span className='Title__Blue'>T</span>
              ECHNOLOGY
            </div>
            <span className='Title__Blue'>ON</span>
          </span>
          <span className='Content'>
            <div>A leading Company</div>
            <div>producing Digital</div>
            <div>Broadcasting Equipments.</div>
          </span>
          <Link to='/introduce'>
            <Button variant='contained'>Introduce More</Button>
          </Link>
        </div>
      </div>
      <div className='HomeIntroduceBlock'>
        <div className='HomeIntroduceBlock__Content'>
          <span className='Title'>
            <div>Digital</div>
            <div>Headend</div>
            <div>Equipments</div>
          </span>
          <span className='Content'>
            <div>HD Encodulator,</div>
            <div>8VSB Remodulator,</div>
            <div>UHD Remodulator</div>
            <div>and more ...</div>
          </span>
          <Link to='/products' onUpdate={() => window.scrollTo(0, 0)}>
            <Button variant='contained'>
              Product More
            </Button>
          </Link>
        </div>
        <img
          className='HomeIntroduceBlock__Image'
          src='images/home-product-image1.JPG'
          alt='company-introduce'
        />
      </div>
      <div className='HomeIntroduceBlock'>
        <img
          className='HomeIntroduceBlock__Image'
          src='images/home-file-download-long.jpg'
          alt='company-file-download'
        />
        <div className='HomeIntroduceBlock__Content'>
          <span className='Title'>Catalog & User Manual</span>
          <span className='Content'>
            <div>You can download </div>
            <div>Catalogs & User manuals</div>
            <div>what you want</div>
          </span>
          <Link to='/support/download'>
            <Button variant='contained'>Download More</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeIntroduceBlock
