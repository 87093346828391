import React, { useState, useEffect } from 'react'
import SearchResultComponent from 'components/Search/SearchResultComponent'
import Button from '@material-ui/core/Button'

import './SearchResultList.scss'

const DEFAULT_INDEX = 3

const SearchResultList = ({ mainTitle, data, setData, getSearchData }) => {
  const { results, page } = data
  const [showButton, setShowButton] = useState(true)
  const [resultIndex, setResultIndex] = useState(DEFAULT_INDEX)

  const handleButton = () => {
    setResultIndex(resultIndex + DEFAULT_INDEX)

    if (resultIndex % 9 === 0) {
      getSearchData(data, setData)
    }
  }

  useEffect(() => {
    if (resultIndex > results.length) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  }, [results])

  useEffect(() => {
    if (page === 1) {
      setResultIndex(DEFAULT_INDEX)
    }
  }, [page])

  if (data.results.length === 0) {
    return (
      <div />
    )
  }

  return (
    <div className='SearchResultList' key={data.categoryUrl}>
      <h2 className='SearchResultList__Header'>{mainTitle}</h2>
      <hr />
      {results.slice(0, resultIndex).map((result, index) => {
        const {
          title, content, url: resultUrl, datetime,
        } = result
        const key = `${title}-${index}`

        return (
          <SearchResultComponent
            title={title}
            content={content}
            url={resultUrl}
            datetime={datetime}
            key={key}
          />
        )
      })}
      {showButton && (
        <div className='SearchResultList__ButtonBox'>
          <Button
            className='SearchResultList__ShowMoreButton'
            variant='outlined'
            type='submit'
            onClick={() => handleButton()}
          >
            더보기
          </Button>
        </div>
      )}
    </div>
  )
}

export default SearchResultList
