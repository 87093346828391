import React from 'react'
import './BusinessAreaComponent.scss'

function BusinessAreaComponent() {
  return (
    <div className='BusinessAreaComponent'>
      <h2>사업 영역</h2>
      <img src='/images/business-area.JPG' alt='사업 영역' />
    </div>
  )
}

export default BusinessAreaComponent
