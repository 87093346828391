/* eslint-disable */
import React, { useEffect, useState } from 'react'
import LoadingIndicator from 'components/LoadingIndicator'
import { DEFAULT_URL, PRODUCT_URL, TEMP_DEFAULT_URL } from 'apis/restApis'

import { useSelector, useDispatch } from 'react-redux'
import { setProduct } from 'redux/actions'

import axios from 'axios'
import ModalImage from 'react-modal-image'
import Swal from 'sweetalert2'
import Button from '@material-ui/core/Button'
import './ProductDetailComponent.scss'

const {
  BASE,
} = PRODUCT_URL

function ProductDetailComponent(props) {
  const isLogin = useSelector(state => state.isLogin)
  const dispatch = useDispatch()

  const { match } = props
  const { params: { id } } = match

  const [selectedProductInfo, setSelectedProductInfo] = useState({})
  const [crntHtml, setCrntHtml] = useState('')
  const [keyFeature, setKeyFeature] = useState('')
  const [productImgUrl, setProductImgUrl] = useState('')
  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false)

  const tempProductImage = '/images/product_temp_image.jpg'

  const getProduct = () => {
    const url = `${DEFAULT_URL}${BASE}/${id}`

    axios.get(url)
      .then(({ data }) => {
        if (data == null) {
          return null
        }
        const { result, payload } = data
        if (result !== true) {
          return
        }
        if (payload == null) {
          return
        }

        const { product, productFile } = payload
        const { keyFeature, contentHtml } = product
        setSelectedProductInfo(product)
        setKeyFeature(keyFeature)
        setCrntHtml(contentHtml)

        dispatch(setProduct(product))

        // 제품 이미지 임시 코드 !!정식 오픈 시 삭제!
        if (productFile.length > 0) {
          const crntProductImgUrl = TEMP_DEFAULT_URL + productFile[0].url
          setProductImgUrl(crntProductImgUrl)
        } else {
          setProductImgUrl(tempProductImage)
        }
      }, () => Swal.fire('에러가 발생했습니다. 새로고침을 해주세요. \n 계속 이 메시지가 보인다면 관리자에게 문의해주세요'))
      .then(
        setIsLoaded(true), [id],
      )
  }

  useEffect(() => {
    getProduct()
  }, [])

  if (!isLoaded) {
    return (
      <div className='ProductLoadingIndicatorDiv'>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div className='ProductDetailComponent'>
      <div className='ProductDetailWrap'>
        {isLogin
          && (
            <div className='btnWrapper'>
              <Button className='adminBtn' variant='outlined' href={`/products/editor`}>내용 수정</Button>
              <hr />
            </div>
          )}

        <div className='ProductNameDiv'>
          <h1 className='ProductNameText'>
            {selectedProductInfo.productName}
          </h1>
        </div>

        <div className='UpperContent'>
          <div className='PhotoBlockWrap'>
            <div className='PhotoBlock'>
              <ModalImage
                className='ProductImage'
                small={productImgUrl}
                large={productImgUrl}
                alt='product img'
              />
            </div>
          </div>

          <div className='RightBlock'>
            <h2>제품의 특징</h2>
            <div className='RightContent'>
              {keyFeature
                && <div dangerouslySetInnerHTML={{ __html: keyFeature }} />}
            </div>
          </div>
        </div>

        <div className='BottomContent'>
          {crntHtml
            && <div className='detailPrintOut' dangerouslySetInnerHTML={{ __html: crntHtml }} />}
        </div>

      </div>
    </div>
  )
}

export default ProductDetailComponent
