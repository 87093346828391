// libraries
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'

// constants
import { DEFAULT_URL, DOWNLOAD_URL, FILE_URLS, HEADERS } from 'apis/restApis'

// material-ui
import Button from '@material-ui/core/Button'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SaveIcon from '@material-ui/icons/Save'
import PublishIcon from '@material-ui/icons/Publish'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { green } from '@material-ui/core/colors'

// CSS
import './DownloadAdminEditComponent.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const { UPLOAD_FILE, DOWNLOAD_INSERT } = FILE_URLS

// 기존에 존재하는 다운로드 id에 대한 수정 (내용 수정 및 추가)
const DownloadAdminEditComponent = ({
  downloadType, id, targetName, tableType, getDownloadData,
}) => {
  const authToken = useSelector(state => state.user.token.token)
  const headers = {
    ...HEADERS,
    'Content-Type': 'multipart/form-data',
    'X-AUTH-TOKEN': authToken,
  }
  // 수정할 데이터 저장할 변수
  const [newCode, setNewCode] = useState('') // 'PRODUCT' or 'APPLICATION'
  const [newTargetName, setNewTargetName] = useState('')
  // 생성과 관련된 변수
  const [isAdd, setIsAdd] = useState(false)
  // 추가할 데이터 저장할 변수
  const [addFile, setAddFile] = useState(null)
  const labelId = `add-file-upload-${id}`

  const sendFileToServer = () => {
    // 백엔드 서버로 보냄. 파일만 보내면 된다
    const url = `${DEFAULT_URL}${UPLOAD_FILE}`
    const formData = new FormData()
    formData.append('file', addFile)

    const config = {
      url,
      data: formData,
      headers,
      method: 'POST',
    }

    axios(config)
      .then(res => console.log(res))
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  const sendFileToDatabase = () => {
    // 디비로 보냄. 
    const INSERT_DOWNLOAD_URL = DOWNLOAD_INSERT[downloadType]
    const url = `${DEFAULT_URL}${INSERT_DOWNLOAD_URL}`
    const formData = new FormData()
    formData.append('file', addFile)
    formData.append('id', id)
    const config = {
      url,
      data: formData,
      headers,
      method: 'POST',
    }
    axios(config)
      .then(res => {
        const { data: { payload } } = res
        Swal.fire(payload)
        setAddFile(null)
        setIsAdd(false)
        getDownloadData()
      })
      .catch(() => Swal.fire('데이터를 받아오는데 실패하였습니다.'))
  }

  const handleSubmit = e => {
    e.preventDefault()
    sendFileToServer()
    sendFileToDatabase()
  }

  // 수정시 modal 관련 변수, 메소드
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setNewCode(tableType)
    setNewTargetName(targetName)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // 수정하기
  const handleEdit = () => {
    const UPDATE_URL = DOWNLOAD_URL.UPDATE[downloadType]
    const url = `${DEFAULT_URL}${UPDATE_URL}/${id}`
    const formData = new FormData()
    formData.append('code', newCode)
    formData.append('targetName', newTargetName)
    handleClose()
    const config = {
      url,
      data: formData,
      headers,
      method: 'put',
    }
    axios(config)
      .then(res => {
        if (res.data.result) {
          Swal.fire('수정되었습니다.')
          getDownloadData()
        }
      })
      .catch(() => Swal.fire('수정을 실패하였습니다.'))
  }

  return (
    <span className='DownloadAdminEditComponent'>
      {/* 파일 추가 */}
      <Button onClick={() => setIsAdd(!isAdd)}>
        <AddCircleIcon style={{ color: isAdd ? green[600] : null }} />
      </Button>
      {isAdd && (
        <div className='DownloadAdminFiles__FileBox'>
          <form onSubmit={e => handleSubmit(e)} encType='multipart/form-data'>
            <div>
              <Button variant='contained' ctype='submit'>
                <label className='DownloadAdminFiles__Label' htmlFor={labelId}>
                  <PublishIcon />업로드
                  <input
                    type='file'
                    id={labelId}
                    name='addFile'
                    accept='image/*'
                    style={{ display: 'none' }}
                    onChange={e => setAddFile(e.target.files[0])}
                  />
                </label>
              </Button>
              <div>{addFile?.name}</div>
            </div>
            <div className='DownloadAdminFiles__ButtonBox'>
              {
                addFile === null
                  ? <Button variant='contained' disabled><SaveIcon />저장</Button>
                  : <Button variant='contained' color='primary' type='submit'> <SaveIcon /> 저장</Button>

              }
            </div>
          </form>
        </div>
      )}
      {/* 수정 */}
      <Button onClick={() => handleOpen()}><EditRoundedIcon /></Button>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className='Modal'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className='Modal__Inside'>
            <div className='Modal__Inside__Icon'>
              <CloseIcon onClick={handleClose} />
            </div>
            <h2
              id='transition-modal-title'
              className='Modal__Inside__Title'
            >
              {downloadType} 수정
            </h2>
            <div id='transition-modal-description'>
              <div className='Modal__Inside__Input'>
                <FormControl required>
                  <InputLabel id='demo-mutiple-name-label'>{downloadType} Type</InputLabel>
                  <Select
                    labelId='demo-mutiple-name-label'
                    id='demo-mutiple-name'
                    value={newCode}
                    onChange={e => setNewCode(e.target.value)}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value='PRODUCT'>
                      PRODUCT
                    </MenuItem>
                    <MenuItem value='APPLICATION'>
                      APPLICATION
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  required
                  id='standard-textarea'
                  fullWidth
                  label='NAME'
                  value={newTargetName}
                  onChange={e => setNewTargetName(e.target.value)}
                />
                <div className='Modal__Inside__Button'>
                  {
                    newTargetName === ''
                      ? <Button variant='contained' disabled>수정하기</Button>
                      : <Button variant='contained' onClick={() => handleEdit()}>수정하기</Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </span>
  )
}

export default DownloadAdminEditComponent
