/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { Editor } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor.css' /* eslint-disable-line */

import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';


export const templateText = `
<p><span style='color: #b8b8b8'>===========================================================</span></p>
<p><em><span style='color: #b8b8b8'>본 제품의 설명은 비어있습니다. 아래 템플릿을 필요에 따라 편집하여 사용하시기 바랍니다.</span></em></p>
<p><span style='color: #b8b8b8'>===========================================================</span></p>

<h2>Overview</h2>
<p>상세 텍스트가 들어갈 공간입니다.</p>

<h2>Input Specification</h2>
<ul>
  <li>첫 번째 스펙 설명</li>
  <li>두 번째 스펙 설명</li>
  <li>세 번째 스펙 설명</li>
</ul>

<h2>Output Specification</h2>
<ul>
  <li>첫 번째 스펙 설명</li>
  <li>두 번째 스펙 설명</li>
  <li>세 번째 스펙 설명</li>
</ul>

<h2>Data Processing</h2>
<ul>
  <li>첫 번째 프로세싱 설명</li>
  <li>두 번째 프로세싱 설명</li>
  <li>세 번째 프로세싱 설명</li>
</ul>

<h2>Physical Power</h2>
<ul>
  <li>첫 번째 상세 설명</li>
  <li>두 번째 상세 설명</li>
  <li>세 번째 상세 설명</li>
</ul>`

const customToolbar = [
  ['heading', 'bold', 'italic'],
  ['hr'],
  ['ul', 'ol'],
]

const noToolbar = [
  []
]

const CustomEditor = ({ textOnly, initialContent, setEditorContent, isProduct }) =>  {
  const editorRef = useRef()

  const [initText, setInitText] = useState(initialContent)

  const getTemplateText = () => {
    if (isProduct) {
      if (!initialContent) {
        setInitText(templateText)
      } else if (initialContent.trim().length == 0) {
        setInitText(templateText)
      }
      editorRef.current.getInstance().setHTML(initText, false)
    }
    setEditorContent(initText)
  }

  useEffect(() => {
    getTemplateText()
  }, [initText])

  const handleChange = () => {
    const innerText = !textOnly ?
      editorRef.current.getInstance().getHTML() :
      editorRef.current.getInstance().getMarkdown()
    setEditorContent(innerText)
  }

  return (
    <Editor
      initialValue={initText}
      height={'auto'}
      usageStatistics={false}
      initialEditType={
        !textOnly ? 'wysiwyg'
        : 'markdown'
      }
      toolbarItems={
        !textOnly ? customToolbar
        : noToolbar
      }
      onChange={() => handleChange()}
      plugins={
        !textOnly ? [colorSyntax]
      : []}
      ref={editorRef}
    />
  )
}

CustomEditor.defaultProps = {
  textOnly: false,
  isProduct: false,
  height: '560px',
}

export default CustomEditor

