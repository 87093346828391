export const DEFAULT_URL = 'https://huton.co.kr/api'

export const LOCAL_URL = 'http://localhost:3000'

export const HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, PATCH, OPTIONS',
}

export const PRODUCT_URL = {
  BASE: '/products',
  CREATE: '/products/insert',
  UPDATE: '/products/update',
}
export const TEMP_DEFAULT_URL = 'http://huton.co.kr'

export const SUPPORT_URL = {
  BASE: '/support',
  NOTICE: '/notices',
  NOTICE_UPDATE: '/notices/update',
  NOTICE_CREATE: '/notices/insert',
  NOTICE_DELETE: '/notices/delete',
  FILE_DOWNLOAD: '/files/downloadFile',
}

export const APPLICATION_URL = '/applications'

export const MAP_URL = '/kakao_map_key'

export const LOGIN_URL = '/login'

export const HISTORY_URL = {
  BASE: '/history',
  HISTORY_CREATE: '/history/insert',
  HISTORY_UPDATE: '/history/update',
  HISTORY_DELETE: '/history/delete',
}

export const SEARCH_URL = {
  SEARCH: '/search',
  CATEGORY: {
    PRODUCT_URL: '/products',
    APPLICATION_URL: '/applications',
    CATALOGUE: '/catalogue',
    MANUAL: '/manual',
    NOTICE: '/api/notice',
  },
}

export const ADMIN_URLS = {
  GET_PRODUCTS: '/products',
  UPDATE_PRODUCT: '/products/update',
  DELETE_PRODUCT: '/products/delete',
  CREATE_PRODUCT: '/products/insert',
  GET_CATEGORIES: '/products/category',
  UPDATE_CATEGORIES: '/products/category/update',
  DELETE_CATEGORIES: '/products/category/delete',
  CREATE_CATEGORIES: '/products/category/insert',
}

export const FILE_URLS = {
  UPLOAD_FILE: '/files/uploadFile',
  APPLICATION_DELETE: '/files/application/delete',
  APPLICATION_INSERT: '/files/application/insert',
  PRODUCT_INSERT: '/files/product/insert',
  DOWNLOAD_INSERT: {
    Catalog: '/files/catalog/insert',
    Manual: '/files/manual/insert',
  },
  DOWNLOAD_DELETE: {
    Catalog: '/files/catalog/delete',
    Manual: '/files/manual/delete',
  },
  FILE_DOWNLOAD: '/files/downloadFile',
}

export const APPLICATIONS_URL = {
  GET_SYSTEMS: '/applications',
  INSERT_SYSTEMS: '/applications/insert',
  DELETE_SYSTEMS: '/applications/delete',
  UPDATE_SYSTEMS: '/applications/update',
}

export const DOWNLOAD_URL = {
  CATALOGS: '/catalogs',
  MANUALS: '/manuals',
  INSERT: {
    Catalog: '/catalogs/insert',
    Manual: '/manuals/insert',
  },
  UPDATE: {
    Catalog: '/catalogs/update',
    Manual: '/manuals/update',
  },
}
