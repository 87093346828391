import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import SecondNavigation from 'components/SecondNavigationBar'
import LoginComponent from 'components/Admin/LoginComponent'
import AdminProducts from 'components/Admin/AdminProducts'
import AdminCategory from 'components/Admin/AdminCategory'
import AdminApplication from 'components/Admin/AdminApplication'
import { TAB_TYPE } from 'constants/constants_pb'

import './AdminPage.scss'

const { PRODUCTS, SYSTEMS, CATEGORIES } = TAB_TYPE

const renderPreparationPage = () => (
  <div className='Page-Ready'>
    <div className='icons8-info' />
    <h1>페이지 준비중입니다.</h1>
    <h3>보다 나은 서비스 제공을 위하여 페이지를 준비중에 있습니다.</h3>
    <h3>빠른 시일내에 준비하여 찾아뵙겠습니다.</h3>
  </div>
)

const AdminPage = ({ location }) => {
  // state from redux
  const { username: currentUsername } = useSelector(state => state.user)
  const isLogin = useSelector(state => state.isLogin)

  // tabType
  const [tabType, setTabType] = useState(CATEGORIES)

  const renderTabContents = () => {
    switch (tabType) {
      case CATEGORIES:
        return <AdminCategory />
      case PRODUCTS:
        return <AdminProducts />
      case SYSTEMS:
        return <AdminApplication />
      // return renderPreparationPage()
      default:
        return renderPreparationPage()
    }
  }

  if (!isLogin) {
    return <LoginComponent currentUsername={currentUsername} />
  }
  return (
    <div className='AdminPage'>
      <SecondNavigation pathname={location.pathname} />
      <div className='AdminPage__Admin'>
        <div className='AdminPage__TabBox'>
          <div
            className={classNames('AdminPage__Tab', { 'AdminPage__Tab--selected': tabType === CATEGORIES })}
            onClick={() => setTabType(CATEGORIES)}
          >
            <span>PRODUCT GROUP 관리</span>
          </div>
          <div
            className={classNames('AdminPage__Tab', { 'AdminPage__Tab--selected': tabType === PRODUCTS })}
            onClick={() => setTabType(PRODUCTS)}
          >
            <span>PRODUCT 관리</span>
          </div>
          <div
            className={classNames('AdminPage__Tab', { 'AdminPage__Tab--selected': tabType === SYSTEMS })}
            onClick={() => setTabType(SYSTEMS)}
          >
            <span>APPLICATION 관리</span>
          </div>
        </div>
        {renderTabContents()}
      </div>
    </div>
  )
}

export default AdminPage
